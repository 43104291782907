/**
 * Created by imranexpt on 25/10/2016.
 */
FoodFriendsApp.factory("FFApi", function($http, $state, $window){
    return{
        hasAuth : function(){
            return this.getData("username") && this.getData("password") ? true : false;
        },
        getHttp :  function(){ return $http; },
        callAPI : function(url, params) {
            if(!_.has(params, 'username')){
                params['username'] = this.getData("username");

            }
            if(!_.has(params, 'password')){
                params['password'] = this.getData("password");
            }
            if(!_.has(params, 'date')) params['date'] = moment().format("YYYY-MM-DD");
            if(!_.has(params, 'deviceID')) params['deviceID'] = deviceID;
            if(!_.has(params, 'lang')) params['lang'] = this.lang;
            var schedule = this.getActiveSchedule();
            params['schedule_ID'] = schedule ? schedule.ID : 0;
            params['schedule_type'] = schedule ? schedule.type : 'staff';
            return $http.post("http://www.foodfriends.com/foodfriends_api_v2/" + url, params,{timeout : 10000});
        },
        logout : function(){
            this.setData("username", "");
            this.setData("password", "");
            this.setData("schedule_ID", "");
            this.setData("myInfo", "");
            $window.sessionStorage.removeItem('fbtoken');
            $state.go('splash');
        },
        setActiveSchedule : function(schedule_id){
            this.setData("schedule_ID", schedule_id);
        },
        getActiveSchedule : function(schedule_id){
            schedule_id = _.isUndefined(schedule_id) ? (this.getData("schedule_ID") || 0) : schedule_id;
            var schedules = this.getData("schedules");
            schedules = schedules ? JSON.parse(schedules) : [];
            var schedule = _.find(schedules, {ID : _.parseInt(schedule_id)});
            if(!schedule) return false;
            schedule.picture = _.has(schedule,'picture') && _.size(schedule.picture) > 0 ? schedule.picture : 'images/restaurant_blank.jpg';
            return schedule;
        },
        alert : function(msg, title){
            if(typeof(cordova) !== 'undefined') {

                navigator.notification.alert(msg, null, (!_.isUndefined(title) ? title : 'FoodFriends'));
            }else {
                console.log("cordova is undefinedd");
                alert(msg);
            }
        },
        handleResponse : function(response){
            if(_.has(response, 'profile')){
                $state.go('register-complete');
                return false;
            }
            if(_.has(response, 'loginError')){
                this.logout();
                return false;
            }
            if(response.success !== true){
                this.alert(response.errors);

                return false;
            }
            return true;
        },
        confirm : function(msg, callback){
          if(typeof(cordova) === 'undefined')
              return confirm(msg);
          else
              navigator.notification.confirm(msg,callback,'FoodFriends');
        },
        isAjax : function(){
            if(!$state.is('login'))
            return $http.pendingRequests.length > 0;
        },
        getData : function(key, defVal){
            defVal = _.isUndefined(defVal) ? false : defVal;
            var val = localStorage.getItem(key);
            return _.isUndefined(val) || _.isNull(val) ? defVal : val;
        },
        setData : function(key, value){
            if(value === '') return localStorage.removeItem(key);
            return localStorage.setItem(key, value);
        },
        lang : 'sw',
        getLangCode : function(){ return this.lang; },
        setLang : function(lng){
            if(_.isUndefined(lng)){
                _lang = this.getData("lang") || "sw";
                this.setData("lang", _lang);
                this.lang = _lang;
                moment.locale('sv');
            }else{
                this.setData("lang", lng);
                this.lang = lng;
                moment.locale('en');
            }
        },
        getLng : function(){ return this.lang; },
        search_query : '',
        search_tab : 'restaurant',
        sharedData : {},
        homeCache : {members : 0, jobs : 0, restaurants : 0, member_list : [], all_jobs : [], restaurant : {}, last_accessed : 0, blog : []},
        setSharedData : function(d){ this.sharedData = d;},
        getSharedData : function(){ var d = this.sharedData; this.sharedData = {}; return d;},
        getLang : function(key){
            return _.has(translations, key) ? translations[key][this.lang] : key;
        },
        getLinkedInProfile : function(code){
            return $http.get('https://api.linkedin.com/v1/people/~:(first_name,last_name,email-address,location,picture-url)?oauth2_access_token=' + code + '&format=json');
        },
        work_list : [
            {en : 'Owner', sw : 'Krögare'},
            {en : 'Restaurant manager', sw : 'Restaurangchef'},
            {en : 'Maitre d', sw : 'Hovmästare'},
            {en : 'Chef', sw : 'Kock'},
            {en : 'Kallskanka', sw : 'Kallskänka'},
            {en : 'Sous chef', sw : 'Souchef'},
            {en : 'Kitchen manager', sw : 'Köksmästare'},
            {en : 'Dish washer', sw : 'Diskare'},
            {en : 'Nisse', sw : 'Nisse'},
            {en : 'Waiter', sw : 'Servis'},
            {en : 'Bartender', sw : 'Bartender'},
            {en : 'Bar nisse', sw : 'Barnisse'},
            {en : 'Sommelier', sw : 'Sommelier'},
            {en : 'Dining room manager', sw : 'Matsalschef'}
        ],
        getWorkOption : function(opt) {
            var wk = _.find(this.work_list, {en: opt});
            if (wk) return wk[this.lang];
            return opt;
        }

    };
});
/**
 * Created by IMRANEXPT on 10/17/15.
 */
var translations = {
    schedule_menu_schedule : {en : 'Full Schedule', sw : 'Schema'},
    schedule_menu_my_schedule : {en : 'My Schedule', sw : 'Mitt Schema'},
    schedule_menu_swap_requests : {en : 'Swap Requests', sw : 'Byten'},
    schedule_menu_unassigned : {en : 'Unassigned', sw : 'Lediga Pass'},
    schedule_menu_add_shift : {en : 'Add Shift', sw : 'Nytt pass'},
    schedule_msg_no_shifts : {en : "No shifts found for", sw : "Inga pass för"},
    schedule_filter_shifts_all : {en : 'All', sw : 'Alla'},
    schedule_filter_shifts_unassigned : {en : 'Unassigned', sw : 'Lediga Pass'},
    schedule_filter_shifts_swap : {en : 'Swap requests', sw : 'Byten'},
    schedule_filter_sort_name : {en : 'Name', sw : 'Namn'},
    schedule_filter_sort_type : {en : 'Type', sw : 'Typ'},
    schedule_filter_sort_time : {en : 'Start time', sw : 'Starttid'},
    schedule_filter_show_all : {en : 'All', sw : 'Alla'},
    schedule_filter_btn : {en : 'Save filter', sw : 'Spara'},
    shift_swapped_success : {en : "Shift is swapped successfully", sw : "Bytet har godkänts"},
    title_shift_details : {en : "Shift Details", sw : "Pass"},
    schedule_no_schedules : {en : "You don't have any schedules, you can try adding new from web version", sw : "Du har inget schema. Lägg upp schemat på www.foodfriends.com"},
    home_box_1 : {en : 'Be first to know about what happens in the restaurant business', sw : 'Bli först att veta vad som händer i krogbranschen'},
    home_box_2 : {en : 'Scheduling Time stamp  with incredible communication  tool', sw : 'Personalliggare Schemaläggning och oslagbar meddelandefunktion'},

    /** sidebar **/
    sb_schedule : {en : 'Schedule', sw : 'Schema'},
    sb_messages : {en : 'Messages', sw : 'Meddelanden'},


    /** my schedule **/
    schedule_my_schedule_avl_msg : {en : 'Set your availibility for', sw : 'Ange tillgänglighet'},
    schedule_my_schedule_avl_btn : {en : "Set time", sw : "Ange tid"},
    btn_confirm : {en : "Confirm", sw : "Bekräfta"},
    btn_approve : {en : "Approve", sw : "Godkänn"},
    btn_accept : {en : "Accept", sw : "Godkänn"},
    btn_assign : {en : "Assign", sw : "Tilldela"},
    btn_swap : {en : 'Swap' , sw : 'Byten'},

    /** cancel shift **/
    cancel_reason_noreason : {en : "No Reason", sw : "Ej angiven anledning."},
    cancel_reason_vab : {en : "VAB", sw : "VAB"},
    cancel_reason_parentalleave : {en : "Parental Leave", sw : "Föräldrarledig"},
    cancel_reason_vacation : {en : "Vacation", sw : "Semester"},
    cancel_reason_sick : {en : "Sick", sw : "Sjuk"},

    /** shift details **/
    button_cancel_shift : {en : "Cancel Shift", sw : "Frånvaro"},
    button_invite_shift : {en : "Invite to shift", sw : "Bjud in"},
    button_assign_shift : {en : "Assign to shift", sw : "Tilldela pass"},
    assign_shift_title : {en : "Assign shift", sw : "Tilldela pass"},
    schedule_no_shifts : {en : "You don't have any shifts at this time", sw : "Det finns inga pass"},
    timepicker_hours : {en : "Hours", sw : "Timmar"},
    timepicker_minutes : {en : "Minutes" , sw : "Minuter"},
    timepicker : {en : "Time Picker", sw : "Välj Tid"},
    button_invite_selected : {en : "Invite selected", sw : "Skicka inbjudan till utvalda"},
    comment_placeholder : {en : "Enter your comment...", sw : "Skriv kommentar"},
    cancel_shift_reason_title : {en : "Cancel shift reason", sw : "Anledning till frånvara"},
    rq_text : {en : "Shift has been requested for leave", sw : "Har bett om ledigt"},
    swp_text : {en : "Shift has been requested for swap with user ", sw : "Begäran om byte med "},
    no_swap_shifts : {en : 'There are no swap requests to display', sw : 'Det finns inga byten att godkänna'},


    /* add shift **/
    add_shift_title : {en : "Add Shift", sw : "Nytt pass"},
    add_shift_select_schedule : {en : "Select a StaffPlanner", sw : "Välj schema"},
    add_shift_select_type : {en : "Select a type", sw : "Välj typ"},
    add_shift_select_date : {en : "Pick date for this shift", sw : "Ange datum"},
    add_shift_select_time : {en : "Select time for shift", sw : "välj tid"},
    add_shift_add_button : {en : "Save shift", sw : "Spara pass"},



    /** messages **/
    messages_heading : {en : "Messages" , sw : "Meddelanden"},
    message_compose_heading : {en : "Compose Message" , sw : "Skriv ett meddelande"},
    message_sent : {en : "Message Sent", sw : "Meddelande skickat"},
    message_placeholder : {en : "Enter your message...", sw : "Meddelande"},
    message_reply_placeholder : {en : "Enter your reply...", sw : "Ditt svar"},
    text_save : {en : 'Save', sw  : 'Spara'},
    /** login register **/
    email_label : {en : 'Email address', sw : 'Epostadress'},
    password_label : {en : 'Password', sw : 'Lösenord'},
    firstname_label : {en : 'First name', sw : 'Förnamn'},
    lastname_label : {en : 'Last name...', sw : 'Efternamn'},
    login_heading : {en : 'Login', sw : 'Logga in'},
    login_button : {en : 'Login', sw : 'Logga in'},
    register_button_label : {en : 'Not yet member? Register now', sw : 'Inte medlem ännu? Registrera dig här.'},
    register_heading : {en : "Register now", sw : "Registrera dig här"},
    register_button : {en : 'Register', sw : 'Registrera dig'},
    hdl_register_complete : {en : 'Complete Registration', sw : 'Registrering'},
    login_button_label : {en : 'Already a member? Login now', sw : 'redan medlem? Logga in nu'},
    logout : {en : 'Logout', sw : 'Logga ut'},
    location_label : {en : "Enter your city name", sw : "Ort"},
    company_label : {en : "Enter your company name", sw : "Företag"},
    login_desc : {en : 'Login with your email to get acess in your own space in restaurant business', sw : 'Logga in och ta del av branschens eget nätverk'},
    login_header : {en : 'Welcome!', sw : 'Välkommen'},
    text_send			:{en : 'Send', sw : 'Skicka'},
    message_group_all	:	{en : 'ALL', sw : 'ALLA'},
    message_group_rests	: 	{en : 'Restaurants', sw : 'Restauranger'},
    message_group_types : 	{en : 'TYPES', sw : 'TYPER'},
    message_group_groups : 	{en : 'GROUPS', sw : 'GRUPPER'},
    text_group : {en : 'Group' , sw : 'Grupp'},
    text_filter_users 	: 	{en : 'type to filter users', sw : 'Filtrera användare'},
    text_members : {en : 'Members', sw : 'Användare'},
    add_shift_tin 		: 	{en : 'TIME IN', sw : 'TID IN'},
    add_shift_tout 		: 	{en : 'TIME OUT', sw : 'TID UT'},
    add_shift_add_brk 	:	{en : 'Add Break', sw : 'Rast'},
    add_shift_txt_assigned : { en : 'Assigned User' , sw : 'Tilldelad'},
    add_shift_txt_invited	:{en : 'Invited Users' , sw : 'Inbjudna'},
    shift_add_comment	:	{en : 'add comment to this shift', sw : 'kommentar till pass'},
    text_confirm_shift	:	{en : 'Confirm this shift to get assigned', sw : 'Bekräfta pass'},
    text_availibility : {en : 'You set your availibility to', sw : 'Ange tillgänglighet för'},
    text_nowork	:	{en : 'You set not to work this day.', sw : 'Icke tillgänglig denna dag.'},
    text_unset_work	:	{en : 'UNSET' , sw : 'Nollställ'},
    text_set_work	:	{en : 'I want to work', sw : 'Jag vill jobba.'},
    text_checkin	:	{en : "Check-in", sw : "Stämpla in"},
    i_work : {en : 'I work here', sw : 'Jag har arbetat här'},
    text_checkout	:	{en : "Check-out", sw : "Stämpla ut"},
    text_no_checked_in : {en : 'Not checked in', sw : 'Ej instämplad'},
    guide_title : {en : 'Suppliers', sw : 'Branschregister'},
    text_shift_swapped : {en : 'Shift is Swapped', sw : 'Passet är bytt'},
    text_shift_noconfirm : {en : ' This shift has not been confirmed by user yet', sw : ' Passet ännu ej bekräftat'},
    text_shift_cancelled : {en : 'Shift has been cancelled', sw : 'Passet är avbokat'},
    text_no_comments : {en : 'No comments found.', sw : 'Inga kommentarer.'},
    text_choose_reason : {en : 'Please choose a reason below', sw : 'Ange anledning'},
    text_ask_request_leave : {en : 'Do you want to request leave for this shift?', sw : 'Vill du boka av detta pass?'},
    btn_req_leave : {en : 'Request leave', sw : 'Be om ledigt'},
    text_shift_comments : {en : 'Shift Comments' , sw : 'Kommentarer'},
    sb_profile	:	{en : 'Profile', sw : 'Profil'},
    sb_suppliers : {en : 'Suppliers', sw : 'Branschregister'},
    cancel : {en : 'Cancel', sw : 'Avbryt'},
    register_home_btn : {en : 'Register', sw : 'Bli medlem'},
    login_home_btn : {en : 'Login', sw : 'Logga in'},
    forget_password_label : {en : "Forget password?", sw : "Glömt lösenord?"},
    reset_password_heading : {en : 'Reset password', sw : 'Återställ lösenord'},
    continue_btn : {en : 'Continue', sw : 'Fortsätt'},
    reset_pass_code : {en : 'Enter 4 digit code', sw : 'Enter 4 digit code'},
    reset_pass_desc : {en : "Enter your email address below to get a confirmation code to reset your password", sw : "Ange din epostadress nedan så skickar vi instruktioner för återställande av lösenord"},
    guide_no_company : {en : 'There is no company in this category', sw : 'Det finns inga företag att visa'},
    /** new **/
    lbl_new_members : {en : 'New Members', sw : 'Nya medlemmar'},
    lbl_new_rests : {en : 'New Restaurants', sw : 'Nya restauranger'},
    lbl_new_jobs : {en : 'New Jobs', sw : 'Nya jobbannonser'},
    lbl_new_job : {en : 'New Job', sw : 'Nya jobb'},
    btn_read_more : {en : 'Read More', sw : 'Läs mer'},
    hdl_new_restaurant : {en : 'New Restaurant', sw : 'Ny restaurang'},
    hdl_job_summary : {en : 'Summary', sw : 'Info'},
    hdl_job_tasks : {en : 'Tasks', sw : 'Uppgifter'},
    hdl_about_restaurant : {en : 'About restaurant', sw : 'Om restaurangen'},
    hdl_other_details : {en : 'Other details', sw : 'Andra uppgifter'},
    hdl_post_reply : {en : 'Post your reply', sw : 'Skicka svar'},
    desc_post_reply : {en : 'Post your reply to this job', sw : 'Skicka svar angående detta jobb'},
    btn_send_reply : {en : 'Send Reply', sw : 'Skicka svar'},
    error_guide_no_products : {en : 'No products added to this company yet', sw : 'Detta företag har inga tjänster'},
    txt_message : {en : 'Message', sw : 'Meddelande'},
    txt_messages : {en : 'Messages' , sw : 'Meddelanden'},
    lbl_profile_workat : {en : 'Worked together at', sw : 'Jobbade tillsammans på'},
    btn_edit_profile : {en : 'Edit Profile', sw : 'Ändra profil'},
    txt_edit : {en : 'Edit', sw : 'Ändra'},
    hdl_add_work : {en : 'Add your work experience', sw : 'Yrkeserfarenhet'},
    work_experience_desc : {en : 'Fill up your profile by adding your work experience', sw : 'Komplettera din profil. Var har du jobbat?'},
    desc_add_work : {en : 'Add your work history to level up your profile. The most work experience you add the most co-workers you can have.', sw : 'Lägg till din arbetserfarenhet till din profil så hittar du dina gamla kollegor i nätverket.'},
    btn_add_work : {en : 'Add work experience', sw : 'Lägg till erfarenhet'},
    error_no_jobs : {en : 'There are no jobs available yet', sw : 'Det finns inga jobbannonser att visa'},
    footer_home : {en : 'Home', sw : 'Hem'},
    footer_jobs : {en : 'Jobs', sw : 'Jobb'},
    footer_guide : {en : 'Guide', sw : 'Guide'},
    footer_schedule : {en : 'Schedule', sw : 'Schema'},
    footer_profile : {en : 'Profile', sw : 'Profil'},
    lbl_connect_li : {en : 'Connect with LinkedIn', sw : 'LinkedIn Connect'},
    lbl_connect_fb : {en : 'Connect with Facebook', sw : 'Facebook Connect'},
    error_firstname : {en : 'First name is required!', sw : 'Du måste ange förnamn'},
    error_email : {en : 'A valid email address is required!', sw : 'Du måste ange epost'},
    error_password : {en : 'Password is required (Min length: 5 chars)!', sw : 'Du måste ange ett lösenord (minst 5 tecken.)'},
    error_dateofbirth : {en : 'Date of birth is required', sw : 'Du måste ange födelsedatum'},
    lbl_dateofbirth : {en : 'Date of birth', sw : 'Födelsedatum'},
    error_profile_pic : {en : 'Please select your profile picture', sw : 'Välj en bild'},
    notice_email_confirm : {en : 'An email has been sent with a confirmation link. Please confirm your email before completing your profile.', sw : 'Ett epost med en länk har skickats till dig. Klicka på länken för att aktivera kontot.'},
    desc_register_step2 : {en : 'In order to use the app, you have to complete registration by providing following information.', sw : 'För att aktivera kontot måste du fylla i dessa uppgifter.'},
    lbl_password : {en : 'Enter your password for your account', sw : 'Ange ett lösenord för FoodFriends'},
    error_city : {en : 'City is required!', sw : 'Du måste ange hemstad'},
    lbl_work_title : {en : 'Work title', sw : 'Yrkesbeskrivning'},
    city_label : {en : 'City', sw : 'Stad'},
    work_title_label : {en : 'e.g. Chef at Grand Bistro', sw : 'ex. Kock på Grand Bistro'},
    work_experience_label : {en : 'e.g 10', sw : 'ex. 10'},
    lbl_work_years : {en : 'Years in industry', sw : 'Antal år i branschen'},
    error_work_title : {en : 'Work title is required!', sw : 'Ange yrkesbeskrivning'},
    error_work_years : {en : 'Work experience is required as number of years!', sw : 'Ange antal år i branschen'},
    search_tab_person : {en : 'Person', sw : 'Person'},
    search_tab_restaurant : {en : 'Restaurant', sw : 'Restaurang'},
    search_no_results : {en : 'No results found', sw : 'Inga medlemmar hittades'},
    search_placeholder : {en : "Search restaurants or people", sw : "Sök restaurang eller person"},
    restaurants_heading : {en : "Restaurants", sw : "Restauranger"},
    people_heading : {en : "People at FoodFriends", sw : "Medlemmar"},
    add_headline_pre : {en : "Do you want to create", sw : "Vill du lägga till"},
    not_exists_line : {en : 'does not exist. Do you want to add it?', sw : 'finns inte. Vill du lägga till den'},
    add_headline_post : {en : "as new restaurant?", sw : "som en ny restaurang?"},
    lbl_address : {en : 'Address', sw : 'Adress'},
    lbl_mobile : {en : 'Mobile', sw : 'Mobil'},
    profile_follow : {en : 'Follow', sw : 'FÖLJ'},
    lbl_skype : {en : 'Skype ID', sw : 'Skype ID'},
    lbl_twitter : {en : 'Twitter ID', sw : 'Twitter ID'},
    lbl_work_company : {en : 'Work Company', sw : 'Work Company'},
    share_on_wall : {en : 'Share on wall', sw : 'Dela inlägget'},
    wall_post_lbl : {en : 'What\'s on your mind', sw : 'Skriv publikt meddelande'},
    btn_upload_pic : {en : 'Upload Picture', sw : 'Ladda upp bild'},
    btn_post : {en : 'Post', sw : 'Skicka'},
    btn_comment_reply : {en :'Reply', sw : 'Skicka'},
    lbl_comments : {en : 'Comments' , sw : 'Kommentar'},
    profile_m_info : {en : 'Info', sw : 'Info'},
    profile_m_work : {en : 'Work', sw : 'Jobb'},
    profile_m_activity : {en : 'Activity', sw : 'Aktivitet'},
    profile_m_schedule : {en : 'Schedule', sw : 'Schema'},
    profile_m_mentions : {en : 'Mentions', sw : 'Mentions'},
    add_job_request_header : {en : 'Request to add a new job', sw : 'Intresserad av att lägga in en jobbannons?'},
    add_job_placeholder : {en : 'Enter your comments', sw : 'Meddelande'},
    add_job_btn : {en : 'Send Request', sw : 'Jag vill bli kontaktad'},
    pages_about : {en : 'About FoodFriends', sw : 'Om FoodFriends'},
    pages_advertising : {en : 'Advertising', sw : 'Annonsering'},
    pages_guide : {en : 'Industry Guide', sw : 'Branschguiden'},
    pages_terms : {en : 'Terms & Conditions', sw : 'Användarvillkor'},
    pages_privacy : {en : 'Privacy Policy', sw : 'Sekretesspolicy'},
    label_specialgroup : {en : 'Special Group', sw : 'Stängda grupper'},
    label_group : {en : 'Group', sw : 'Grupper'},
    label_messages : {en : 'Messages', sw : 'Meddelanden'},
    timestamp_at : {en : 'at' , sw : 'kl'},
    label_in : {en : 'in', sw : 'på'},
    label_add_wk : {en : 'Add your work experience', sw : 'Yrkeserfarenhet'},
    lbl_add_wk_new_headline : {en : 'Enter restaurant you have worked in', sw : 'Skriv in restaurang du har jobbat på'},
    lbl_no_wall : {en : 'No wall posts', sw : 'Ingen aktivitet ännu'},
    label_from : {en : 'From', sw : 'från'},
    label_to : {en : 'To', sw : 'till'},
    setting_lang : {en : 'Application Language', sw : 'Språk på appen'},
    label_cur_past_emp  :{en : 'Current and past employees', sw : 'Nuvarande och tidigare medarbetare'},
    i_work_as : {en : 'I work as', sw : 'I work as'},
    lbl_position : {en : 'Position', sw : 'Jobb som'},
    txt_restaurant : {en : 'Restaurant', sw : 'restaurang'},
    title_job_req : {en : 'Thank you', sw : 'Tack för förfrågan'},
    job_req_alert : {en : 'Thank you, your request has been recieved', sw : 'Vi kommer att kontakta dig inom kort.'},
    title_jobs : {en : 'Available Jobs', sw : 'Restaurangfolk'},
    nickname_label : {en : 'Nickname', sw : 'smeknamn'},
    label_other : {en : 'Other', sw : 'Annan'},
    add_rest : {en : "Add Restaurant", sw : "Nytt restaurang"},
    work_no_found : {en : 'No available work history found', sw : 'Ingen erfarenhet har lagts till'},
    rest_contact_title : {en : "Contact details", sw : "Kontaktuppgifter"},
    btn_add_other_work : {en : 'Add to profile', sw : 'Lägg till'},
    text_add_other_work_placeholder : {en : 'Other experience of importance', sw : 'Annan väsentlig erfarenhet'},
    work_connections : {en : 'Work Connections', sw : 'Tidigare kollegor'},
    work_experience : {en : 'Work experience', sw : 'Erfarenhet'},
    profile_info_headline : {en : 'Contact details', sw : 'Kontaktuppgifter'},
    text_premium : {en : 'Contact details are only visible to premium members', sw : 'Kontaktuppgifter endast synliga för premiummedlemmar'},
    btn_been_there : {en : 'Been there', sw : 'Ätit där'},
    btn_like : {en : 'Like', sw : 'Gillar'},
    btn_worked : {en : 'Worked there', sw : 'Jobbat där'},
    click_to_add : {en : 'Click to add', sw : 'Annan väsentlig erfarenhet'},
    delete_job_confirm : {en : "Are you sure to remove this job from your work list?", sw :"Är du säker på att du vill ta bort?"},
    delete_wall_confirm : {en : "Are you sure to delete this wall post?", sw : "Är du säker på att du vill ta bort?"},
    lbl_on_foodfriends : {en : ' on FoodFriends', sw : ' på FoodFriends'},
    placeholder_search : {en : 'Search restaurants...', sw : 'Sök restaurang...'},
    gives_staff_discount : {en : 'Gives staff discount.', sw : 'erbjuder  Krogis-rabatt.'},
    book_now : {en : 'Book now', sw : 'Boka Bord'},
    change_password : {en : 'Change Password', sw : 'Ändra lösenord'},
    old_password : {en : 'Old Password', sw : 'Gammalt lösenord'},
    new_password : {en : 'New password', sw : 'Nytt lösenord'},
    new_password_2 : {en : 'New password again', sw : 'Nytt lösenord igen'},
    password_success : {en : 'Your password has been changed', sw : 'Ditt lösenord har ändrats'},
    forget_pass_email : {en : 'An email has been sent with a reset code, please write down the code below to continue', sw : 'Ett mail har skickats med en kod för att återställa ditt lösenord'},
    forget_pass_success : {en : 'Your password has been reset. Please login with your new password', sw : 'Ditt lösenord har återställts. Logga in med ditt nya lösenord'},
    txt_del_post : {en : 'Delete post', sw : 'Ta bort'}

};
FoodFriendsApp.filter("translate", function(FFApi){
    return function(key){
        var lang = FFApi.lang;
        return _.has(translations, key) ? translations[key][lang] : key;
    };
});
/**
 * Created by IMRANEXPT on 10/30/15.
 */
FoodFriendsApp.filter("momentFormat", function(){
    return function(input, format, niceFormat, short){
        if(!_.isUndefined(niceFormat)){
            var obj = moment(input);
            var minutes = Math.floor((+new Date() - (+obj.valueOf()))/60000);
            if(minutes < 60)
                return minutes + ' min';
            else if (minutes > 60 && minutes < 1440)
                return Math.floor(minutes/60) + ' hours';
            else if(minutes > 1440 && minutes < 2880)
                return 'igår';
            else
                return typeof(short) !== 'undefined' ? obj.format('D MMM') : obj.format("MMMM D, YYYY h:mma");
        }
        return moment(input).format(format);
    };
}).filter('replace', function() {
    return function (input, find, replace) {
        return _.size(input) > 0 ? input.replace(find, replace) : input;
    }
}).filter('simpleTime', function(){
    return function(input){
        //console.log(input);
        var te = /^(\d{2,}):(\d{2})-(\d{2,}):(\d{2})$/g;
        return input.replace(te, '$1 - $3');
    }
})

    .directive("textThumb", function(){
        return {
            restrict : 'E',
            replace : true,
            scope : {
                text : '@',
                multiple : '@',
                count : '@'
            },
            template : '<span class="text-thumb {{textclass}}">{{tname}}<small ng-if="hasMultiple() != false">+{{hasMultiple()}}</small></span>',
            link : function(scope, element, attrs){
                var multiple = false;
                var text = '';

                scope.multiple = scope.multiple ? scope.multiple.split(',') : false;
                //console.log(scope.multiple);
                if(scope.multiple && _.isArray(scope.multiple) && _.size(scope.multiple) > 2)
                    multiple = (scope.multiple.length-1);
                if(scope.count && scope.count > 0)
                    multiple = scope.count;
                if(_.isArray(scope.text) && _.size(scope.text) >= 1) {
                    text = _.first(scope.text);
                }else {
                    text = scope.text;
                }
                text = _.trim(text, ' ');
                if(text.indexOf(' ') >= 0) {
                    text = text.split(' ');
                    var i = 0;
                    var _txt = '';
                    for(var x in text){
                        if(i >= 2) break;
                        text[x] = _.trim(text[x]);
                        if(text[x] == '') continue;
                        i++;
                        _txt = _txt + '' + text[x][0];
                    }
                    text = _txt;
                }else{
                    text = text.substring(0,2);
                }
                scope.tname = text;
                scope.textclass = 'rnd-' + text[0].toLowerCase();
                scope.hasMultiple = function(){ return multiple; };
            }
        }
    })
    .directive('ffImage', function($sce){
    return {
        restrict : 'E',
        replace : true,
        template : '<span class="image-thumb" ng-class="{\'hasMultiple\' : hasMultiple()}"><img ng-src="{{getImage()}}" class="{{classlist}}" alt="" /><small ng-if="hasMultiple() != false">+{{hasMultiple()}}</small></span>',
        scope : {
            image : '@image',
            classlist : '@classes',
            multiple : '@',
            width : '@',
            defaultImage : '@',
            height : '@'
        },
        link : function(scope, element, attrs, ctrl){
            var multiple = false;
            var width = scope.width ? scope.width : 40;
            var height = scope.height ? scope.height : 0;
            var wquery = 'w=' + width;
            if(scope.height)
                wquery += '&h=' + scope.height;
            scope.multiple = scope.multiple ? scope.multiple.split(',') : false;
            if(scope.multiple && _.isArray(scope.multiple) && _.size(scope.multiple) > 2)
                multiple = (scope.multiple.length-1);
            scope.hasMultiple = function(){ return multiple; };
            scope.getImage = function(){
                var img = !scope.defaultImage ? 'http://www.foodfriends.com/static/images/' + scope.image : scope.defaultImage;
                return $sce.trustAsResourceUrl(img);
            }
        }
    }
})
.directive('ffHtml', function($sce) {
    return {
        restrict: 'E',
        replace: true,
        template: '<div ng-bind-html="getHtml()"></div>',
        scope: {
            content : '@'
        },
        link: function (scope, element, attrs, ctrl) {

            scope.getHtml = function () {
                return $sce.trustAsHtml(scope.content);
            }
        }
    }
})
    .filter("humanizeDate", function(FFApi){
        return function(stamp){
            var lang = FFApi.lang;
            var _hours = (lang == 'en' ? ' hours' : ' timmar');
            var _minutes = (lang == 'en' ? ' minutes' : ' minuter');
            var _yesterday = (lang == 'en' ? 'yesterday' : 'i går');
            var _today = (lang == 'en' ? 'today' : 'i dag');
            if(_.isNull(stamp)) return '';
            var _m = moment(stamp);
            var diff = moment().diff(_m);
            //console.log(stamp);
            diff = (diff/1000);
            if(diff <= 3600){
                //console.log(diff);
                return _.parseInt(_.round(diff/60)) + _minutes;
            }else if((diff <= 86400) && (diff > 3600)){
                return _.parseInt(_.round(diff/3600)) + _hours;
            }else{
                diff = _.parseInt(diff/86400);
                if(diff == 1){
                    return _yesterday + (lang == 'en' ? ' at ' : ' kl ') + _m.format('HH.mm');
                }else if(diff == 0){
                    return _today +  (lang == 'en' ? ' at ' : ' kl ') + _m.format('HH.mm');
                }else{
                    return _m.format('D MMM YYYY') +  (lang == 'en' ? ' at ' : ' kl ') + _m.format('HH.mm');
                }
            }
        }
    })
    .filter("humanizeDateFull", function(FFApi){
        return function(stamp){
            return humanizeDuration(stamp,{language : FFApi.lang, units : ['d','mo','y']});
        }
    })
    .directive('whenScrolled', function(){
        return  function (scope, elm, attr) {
                var raw = elm[0];
                raw.addEventListener('scroll', function () {
                    if (raw.scrollTop + raw.offsetHeight >= (raw.scrollHeight)-1) {
                        scope.$apply(attr.whenScrolled);
                    }
                });
        }
});
/**
 * Created by imranexpt on 20/11/2016.
 */
FoodFriendsApp.factory("WorkExperience", function(FFApi){
    return function() {
        return {
            title: '',
            restaurant: '',
            from: '',
            title_other : '',
            to: '',
            restId : 0,
            _addRest : false,
            save: function () {
                return FFApi.callAPI('addWorkExperience', {
                    title: this.title,
                    restaurant: this.restaurant,
                    from: moment(this.from).format("YYYY-MM-DD"),
                    to: moment(this.to).isValid() ? moment(this.to).format("YYYY-MM-DD") : '',
                    restId : this.restId,
                    _addRest : this._addRest
                }).success(function (response) {
                    return response.data;
                });
            }
        }
    }
});
/**
 * Created by imranexpt on 21/11/2016.
 */
FoodFriendsApp.directive("wallPost", function(FFApi, $timeout, $state, $sce, $compile){
   return {
        restrict : 'E',
        scope : {
            post : '=',
            userId : '@'
        },
        templateUrl : 'templates/app-foodfriends/wall-post.html',
        link : function(scope){
            scope.post.post_time_diff = getPostTime(scope.post.post_date);
            scope.post.share_alert = false;
            scope.post.shareable = true;
            scope.post.is_html = scope.post.post_text.indexOf('</span>') < 0 ? (scope.post.is_html || false) : true;
            scope.post.replyComment = '';
            scope.post.post_text_orignal = scope.post.post_text;
            scope.mode = 'view';
            scope.toggleEdit = function(){
                scope.mode = scope.mode == 'view' ? 'edit' : 'view';
            };
            scope.post.typeSet = false;
            scope.post.is_liked = scope.post.is_liked === 0 ? false : true;
            //filterForImages();
            var comments_opened = false;
            var likes_opened = false;
            var comments  = [];
            var likes = [];
            var commentOffset = 1;
            var likesOffset =  1;
            setPostType();
            scope.$watch('post', function() {
                //console.log("New Post", scope.post.post_text);
                setPostType();
            });
            if(scope.post.is_shared == 1)
                scope.post.shareable =  false;
            else if(scope.post.posted_self_user == 1)
                scope.post.shareable = false;

            scope.userId = _.isUndefined(scope.userId) ? 0 : _.parseInt(scope.userId);
            scope.getPostHtml = function(){
                return scope.post.post_text;
            };
            scope.openLikes = function(force){
                if(_.isUndefined(force)) {
                    if (likes_opened == true) {
                        likes_opened = false;
                        return;
                    }
                    if (_.size(likes) > 0 && likes_opened == false) {
                        likes_opened = true;
                        return;
                    }
                }
                FFApi.callAPI("getPostLikes", {post_id : scope.post.post_id, offset : likesOffset}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    if(_.isUndefined(force))
                        likes_opened = !likes_opened;
                    likes = response.likes;
                })
            };
            scope.isSelfLiked = function(){
                return _.find(likes, {self_liked : 1});
            };
            scope.openComments = function(){
                if(comments_opened == true){
                    comments_opened = false;
                    return;
                }
                if(_.size(comments) > 0 && comments_opened == false){
                    comments_opened = true;
                    return;
                }
                FFApi.callAPI("getPostComments", {post_id : scope.post.post_id, offset : commentOffset}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    comments_opened = !comments_opened;
                    comments = response.comments;
                })
            };
            scope.likeUnlikePost = function(){
                FFApi.callAPI('likeWallPost', {post_id : scope.post.post_id}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    scope.post.is_liked = !scope.post.is_liked;
                    if(scope.post.is_liked == true)
                        scope.post.total_likes++;
                    else
                        scope.post.total_likes--;
                    scope.openLikes(true);
                });
            };
            scope.commentsAreOpen = function(){ return comments_opened; };
            scope.likesAreOpen = function(){ return likes_opened; };
            scope.getComments = function(){ return comments; };
            scope.getLikes = function(){ return likes; };
            scope.postComment = function(){
                FFApi.callAPI('postWallComment', {post_id : scope.post.post_id, comment : scope.post.replyComment}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    scope.post.replyComment = '';
                    scope.post.comments++;
                    comments.push(response.comment);
                });

            };
            scope.deletePost = function(){
                if(!confirm(FFApi.getLang('delete_wall_confirm'))) return false;
                FFApi.callAPI('deleteWallPost', {postId : scope.post.post_id}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    $state.reload();
                });
            };
            scope.canDelete = function(){
                if(!$state.is('profile.wall')) return false;
                return scope.post.posted_self_user;
            };
            scope.updateWallPost = function(){
                FFApi.callAPI('updateWallPost', {post : scope.post}).success(function(response){
                    if(!FFApi.handleResponse(response)) return false;
                    scope.toggleEdit();
                });
            };
            scope.isPostShared = function(){
                return scope.userId > 0 && _.has(scope.post, 'shared_user_id') && scope.post.shared_user_id === scope.userId;
            };

            function getPostTime(stamp){
                var _m = moment(stamp);
                var diff = moment().diff(_m);
                diff = (diff/1000);
                if(diff <= 3600){
                    return _.parseInt(_.round(diff/60)) + ' minutes';
                }else if((diff <= 86400) && (diff > 3600)){
                    return _.parseInt(_.round(diff/3600)) + ' hours';
                }else{
                    diff = _.parseInt(diff/86400);
                    if(diff == 1){
                        return 'yesterday at ' + _m.format('HH.mm');
                    }else if(diff == 0){
                            return 'today at ' + _m.format('HH.mm');
                    }else{
                        return _m.format('D MMM YYYY') + ' at ' + _m.format('HH.mm');
                    }
                }
            };
            scope.sharePost = function(){
                if(scope.post.shareable == false) return false;
                FFApi.callAPI("shareWallPost", {postId : scope.post.post_id}).success(function(response){
                   if(!FFApi.handleResponse(response)) return false;
                    scope.post.share_alert = true;
                    scope.post.shareable = false;
                    $timeout(hideAlert, 2000);

                });
            };
            function hideAlert(){
                scope.post.share_alert = false;
            }
            scope.goToUserProfile = function(){
                if(scope.post.posted_self_user == 0)
                    $state.go('profile.public', {userId : scope.post.user_id});
            };

            scope.getPostIframe = function(url){
                return $sce.trustAsResourceUrl(url);
            };
            scope.getPostImage = function(url){
                return $sce.trustAsResourceUrl(url || scope.post.post_text);
            };
            function setPostType(){
                if(scope.post.post_text.indexOf('</span>') >= 0){
                    var el = $compile('<div>' + scope.post.post_text + '</div>')(scope);
                    scope.post.post_text = el.html();
                }
                if(scope.post.typeSet == true) return;
                    var is_youtube = validateYouTubeUrl(scope.post.post_text);
                    if (is_youtube !== false) {
                        scope.post.post_type = 'youtube';
                        scope.post.youtube_url = is_youtube;
                    } else {
                        filterForImages();
                    }
                    scope.post.typeSet = true;

            }
            function filterForImages(){
                var text = scope.post.post_text;
                var regex = /(https?:\/\/[a-z0-9\-\_\.\/]+\.(?:jpe?g|png|gif))[^"]/ig ;

                if(regex.test(text)){
                    scope.post.is_html = true;
                    scope.post.post_text = text.replace(/(http[s]?:\/\/[a-z0-9\-\_\.\/]+\.[?:jpe?g|png|gif]{3,4})[^"]/ig,'<div class="text-center"><img src="\$1" alt=""/></div>');
                    //scope.$apply();
                }else{

                }
            }
            function validateYouTubeUrl(url)
            {
                //var url = $('#youTubeUrl').val();
                if (url != undefined || url != '') {
                    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]{1,11}).*/;
                    var match = url.match(regExp);
                    if (match && _.size(match[2]) == 11) {
                        return 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0';
                    }
                    else {
                        return false;
                    }
                }
            }

        }
   }
});
/**
 * Created by imranexpt on 21/11/2016.
 */
FoodFriendsApp.factory("jobPost", function(FFApi){
    var jobPost = function(job){
        _.extend(this, job);
        this.replyComment = '';
        this.comments_opened = false;
        this.comments_list = [];
        this.commentOffset = 1;
    };

    jobPost.prototype.postComment = function(){
        var job = this;
        FFApi.callAPI('postJobComment', {jobId : this.id, comment : this.replyComment}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            job.replyComment = '';
            job.comments++;
            job.comments_list.push(response.comment);
        });
    };
    jobPost.prototype.commentsAreOpen = function(){
        return this.comments_opened;
    };
    jobPost.prototype.getComments = function(){ return this.comments_list; }
    jobPost.prototype.openComments = function(){
        var job = this;
            if(job.comments_opened == true){
                job.comments_opened = false;
                return;
            }
            if(_.size(job.comments_list) > 0 && job.comments_opened == false){
                job.comments_opened = true;
                return;
            }
            FFApi.callAPI("getJobComments", {jobId : job.id, offset : job.commentOffset}).success(function(response){

                if(!FFApi.handleResponse(response)) return false;
                job.comments_opened = !job.comments_opened;
                job.comments_list = response.comments;
            })
    }
    jobPost.prototype.likeUnLike = function(){
        var job = this;
        FFApi.callAPI("likeUnlikeJob", {jobId : job.id}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            job.is_liked = job.is_liked == 0 ? 1 : 0;
            if(job.is_liked == 1)
                job.total_likes++;
            else
                job.total_likes--;
        });
    };
    return jobPost;
});
/**
 * Created by imranexpt on 18/03/2017.
 */
FoodFriendsApp.directive("workListBox",
    function ($timeout, $document, $compile, $templateCache, FFApi) {
        var template = $templateCache.get("templates/selectBox.html");
        return {
            restrict: 'E',
            scope : {
                work : '='
            },
            template: '<input ' +
            'type="text" ' +
            'readonly="readonly" ' +
            'ng-disabled="disabled == true" ' +
            'class="selectBoxControl"  ' +
            'ng-value="getWorkVal()" ' +
            'ng-click="openPopup($event)" placeholder="' + FFApi.getLang('lbl_position') + '" />',
            //'<i ng-if="delete == true" class="fa fa-times" ng-click="setDate(undefined,$event)"></i>',
            replace: true,
            link: function (scope, element, iAttrs) {
                //scope.time = '';
                //scope.work = modelCtrl.$modelValue ?  modelCtrl.$modelValue : '';

                scope.lang = FFApi.lang;
                scope.work_list = FFApi.work_list;
                console.log(scope.work_list);
                scope.getWorkVal = function(){
                    var wk = _.find(scope.work_list, {en : scope.work});
                    return wk ? wk[scope.lang] : '';
                };
                var selectBoxLinker = $compile(template);
                var selectBox = false;
                scope.disabled = scope.disabled ? scope.disabled : false;
                scope.selectBoxOpened = false;
                scope.openPopup = function (e) {
                    //console.log("Hey");
                    scope.timePickerOpened = true;
                    $timeout(function () {
                        removeSelectBoxPopup();
                        createSelectBoxPopup();
                        $document.find('body').append(selectBox);
                    }, 50);
                };
                scope.setValue = function (v, e) {
                    e.stopPropagation();
                    scope.work = v;
                    //modelCtrl.$setViewValue(v);
                    removeSelectBoxPopup();
                };
                scope.stopEvents = function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                };
                scope.reset = function(e){
                    e.stopPropagation();
                    scope.work = '';
                    removeSelectBoxPopup();
                };
                function createSelectBoxPopup() {
                    console.log("Creating selectbox");
                    // There can only be one tooltip element per directive shown at once.
                    if (selectBox) {
                        removeSelectBoxPopup();
                    }
                    selectBox = selectBoxLinker(scope, function () {
                    });
                    $document.bind("click", removeSelectBoxPopup);
                    // Get contents rendered into the tooltip
                    //scope.$digest();
                }

                function removeSelectBoxPopup(e) {
                    if (selectBox) {
                        selectBox.remove();
                        selectBox = null;
                        $document.unbind("click", removeSelectBoxPopup);
                    }
                }

                scope.$on("$destroy", function () {
                    removeSelectBoxPopup();
                });
            }
        };
    });
/**
 * Created by IMRANEXPT on 10/15/15.
 */
FoodFriendsApp.factory("Shift", function(FFApi, $sce){
   var Shift = function(_shift){
       _shift = _.isUndefined(_shift) ? {} : _shift;
       this.id = _shift.id || 0;
       this.type_ID = _shift.type_ID || 0;
       this.user_ID =   _shift.user_ID || 0;
       this.start_time  =  _shift.start_time || '' ;
       this.end_time    =   _shift.end_time || '';
       this.work_time_start = _shift.start_time || '' ;
       this.work_time_end   =   _shift.start_time || '' ;
       this.checked_in = _shift.checked_in || false;
       this.checked_out = _shift.checked_out || false;
       this.date = moment(_shift.date || '');
       this.tags = _shift.shift_tags || [];
       this.invitations = _shift.invitations || 0;
       this.is_cancelled = _shift.is_cancelled || false;
       this.cancelled_reason = _shift.cancelled_reason || '';
       this.is_invited = _shift.is_invited || false;
       this.shift_time = _shift.shift_time || '';
       this.shift_type = _shift.shift_type || '';
       this.shift_type_flag = _shift.shift_type_flag || '';
       this.type_name = _shift.type_name || '';
       this.user_display_name = _shift.user_display_name || '';
       this.allow_checkinout = _shift.allow_checkinout || false;
       this.allow_swap = _shift.allow_swap || false;
       this.checkin_buttons = _shift.checkin_buttons || [];
       this.checkout_buttons = _shift.checkout_buttons || [];
       this.comments = _shift.comments || [];
       this.request_leave   =   _shift.request_leave || false;
       this.request_swap = _shift.is_swap_request || false;
       this.swap_with = _shift.request_swap || "";
       this.worked_time_rows = _shift.worked_time_rows || [];
       this.user_type = _shift.user_type || 'staff';
       this.breaks =  _shift.shift_time_list || false;
       this._newComment = '';
       this.restaurant_image = _shift.restaurant_image || false;
       this._request_leave = false;
       this._cancel = false;
   };
   Shift.prototype.openCancel = function(){
        if(this.user_type == 'staff'){
            this._cancel = false;
            this._request_leave = true;
        }else{
            this._cancel = true;
            this._request_leave = false;
        }
   };
    Shift.prototype.isAssigned = function(){
        if(this.isAdmin() == false && this.request_leave == true) return false;
        return this.user_ID > 0 && this.is_cancelled == false;
    };
    Shift.prototype.displayTime = function(){
        return this.breaks != false ? this.breaks.join(' , ') : this.shift_time;
    };
    Shift.prototype.getTime = function(){
        return this.breaks == false ? [this.shift_time] : this.breaks;
    };
    Shift.prototype.sendComment = function(){
        var self = this;
        return FFApi.callAPI("addShiftComment", {id : this.id, comment : this._newComment}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            self._newComment = '';
            //self.comments = _.compact([response.comment], self.comments);
            self.comments.unshift(response.comment);
            //self.comments = _.sortBy(self.comments, 'date');
        });
    };
    Shift.prototype.isAdmin = function(){
        return this.user_type == 'admin';
    };
    Shift.prototype.checkin = function(breakid){

    };
    Shift.prototype.getShiftRestImage = function(){
        if(this.restaurant_image == false) return $sce.trustAsResourceUrl('images/restaurant_blank.jpg');
        return $sce.trustAsResourceUrl('http://www.foodfriends.com/static/images/company/' + this.restaurant_image);
    };
    Shift.prototype.approveSwap = function(){
        return FFApi.callAPI("swapRequest", {id : this.id, user_ID : userId}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
    Shift.prototype.requestSwap = function(userId){
        return FFApi.callAPI("swapRequest", {id : this.id, user_ID : userId}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
    Shift.prototype.assign = function(userId){
        return FFApi.callAPI("assignShift", {id : this.id, user_ID : userId}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
    Shift.prototype.confirm = function(){
        if(this.isAdmin()) return false;
        return FFApi.callAPI("confirmShift", {id : this.id}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
    Shift.prototype.isReady = function(type, typeCheck){
        var check = false;
      if(type == 'staff'){
          if(!_.isUndefined(typeCheck))
              check = !this.isAdmin() ? true : false;
          check = (this.is_invited == false && this.request_leave == false && this.request_swap == false);
      }  else{
          if(!_.isUndefined(typeCheck))
              check = this.isAdmin() ? true : false;
      }
        check = (this.user_ID > 0 && this.is_cancelled == false);
        return check;
    };
    Shift.prototype.cancel = function(reason){
        if(this.user_type == 'staff'){
            this._request_leave = false;
            return FFApi.callAPI("requestLeave", {id : this.id}).success(function(response){
                if(!FFApi.handleResponse(response)) return false;
            });
        }
        return FFApi.callAPI("cancelShift", {id : this.id, reason : reason}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
    Shift.prototype.checkinout = function(action, work_row){
        return FFApi.callAPI("shiftCheckInOut", {id : this.id, action : action, break_ID : work_row.bID}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
        });
    };
   return Shift;
});
/**
 * Created by imranexpt on 27/10/2016.
 */
FoodFriendsApp.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/');
    $stateProvider
        .state('login', {
            url:'/login?fbconnect',
            templateUrl: 'templates/login.html',
            controller: 'loginController'
        })
        .state('splash', {
            url:'/splash',
            templateUrl: 'templates/splash.html',
            controller: 'splashController'
        })

        .state('register', {
            url:'/register?fbconnect',
            templateUrl: 'templates/register.html',
            controller: 'registerController'
        })
        .state('reset-password', {
            url:'/reset-password',
            templateUrl: 'templates/reset-password.html',
            controller: 'resetPasswordCtrl'
        })
        .state("register-complete", {
            url : '/register-complete?fromregister&fbConnect',
            templateUrl: 'templates/register-complete.html',
            controller: 'registerCompleteController'
        })
        .state('foodfriends', {
            url: '',
            abstract : true,
            templateUrl : "templates/app-foodfriends/base.html",
            controller : 'foodfriendsBaseCtrl'
        })
        .state("foodfriends.home", {
            url : "/",
            views : {
                'foodfriends@foodfriends' : {
                    templateUrl : 'templates/app-foodfriends/home.html',
                    controller : 'foodfriendsHomeCtrl'
                }
            }
        })
        .state("foodfriends.pages", {
            url : "/pages/?page",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/pages.html",
                    controller : "staticCtrl"
                }
            }
        })
        .state("foodfriends.posts-list", {
            url : "/posts/:type",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/posts-list.html",
                    controller : "postsListCtrl"
                }
            }
        })
        .state("foodfriends.profile", {
            url : "/profile",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/profile.html",
                    controller : "profileController"
                }
            }
        })
        .state("foodfriends.jobs", {
            url : "/jobs/:jobId?",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/jobs.html",
                    controller : "foodfriendsJobsCtrl"
                }
            }
        })
        .state("foodfriends.blog", {
            url : "/blog",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/blog.html",
                    controller : "foodFriendsBlogCtrl"
                }
            }
        })
        .state("foodfriends.search", {
            url : "/search?standalone",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/search.html",
                    controller : "foodfriendsSearchCtrl"
                }
            }
        })
        .state("foodfriends.restaurant", {
            url : "/restaurant",
            views : {
                "foodfriends@foodfriends" : {
                    templateUrl : "templates/app-foodfriends/restaurant.html",
                    controller : "restaurantsCtrl"
                }
            }
        })

        .state("messages", {
            url : "/messages",
            abstract : true,
            templateUrl : 'templates/app-messages/messages.html',
            controller : 'messagesBaseController'
        })
        .state("messages.list", {
            url : '/',
            views : {
                'messages@messages' : {
                    templateUrl: 'templates/app-messages/messages.list.html',
                    controller: 'messagesController'
                }
            }
        })
        .state("messages.message", {
            url : '/message/:messageId',
            views : {
                'messages@messages' : {
                    templateUrl: 'templates/app-messages/messages.message.html',
                    controller: 'messageController'
                }
            }
        })
        .state("messages.message-info", {
            url : '/message-info/:messageId',
            views : {
                'messages@messages' : {
                    templateUrl: 'templates/app-messages/messages.message.info.html',
                    controller: 'messageInfoController'
                }
            }
        })
        .state("messages.compose", {
            url : '/compose',
            views : {
                'messages@messages' : {
                    templateUrl: 'templates/app-messages/messages.message-new.html',
                    controller: 'newMessageController'
                }
            }
        })
        .state("schedule", {
            url : '/schedule',
            abstract : true,
            templateUrl : 'templates/app-staffplanner/schedule.html',
            controller : 'scheduleBase'
        })
        .state("schedule.select", {
            url : '/select-schedule',
            views : {
                'schedule@schedule' : {
                    templateUrl: 'templates/app-staffplanner/schedule.list.html',
                    controller: 'scheduleSelectionController'
                }
            }
        })
        .state("schedule.shifts", {
            url : '/shifts/:date?',
            views : {
                'schedule@schedule' : {
                    templateUrl: 'templates/app-staffplanner/schedule.shifts.html',
                    controller: 'scheduleController'
                }
            }
        })

        .state("schedule.unassigned-shifts", {
            url : '/unassigned-shifts',
            views : {
                'schedule@schedule' : {
                    templateUrl: 'templates/app-staffplanner/schedule.unassigned.html',
                    controller: 'unassignedShiftsController'
                }
            }
        })
        .state("schedule.my-schedule", {
            url : '/my-schedule/:date?',
            views : {
                'schedule@schedule' : {
                    templateUrl: 'templates/app-staffplanner/schedule.my-schedule.html',
                    controller: 'myScheduleController'
                }
            }
        })
        .state("schedule.swap-shifts", {
            url : '/swap-shifts',
            views : {
                'schedule@schedule' : {
                    templateUrl: 'templates/app-staffplanner/schedule.swap-shifts.html',
                    controller: 'swapRequestsController'
                }
            }
        })
        .state("schedule.add-shift", {
            url : '/add-shift',
            views : {
                'schedule@schedule' : {
                    templateUrl : 'templates/app-staffplanner/schedule.add-shift.html',
                    controller : 'addShiftController'
                }
            }
        })
        .state("schedule.add-shift.users", {
            parent : 'schedule.add-shift',
            url : "/users/:action?",
            views : {
                'schedule@schedule' : {
                    templateUrl : 'templates/app-staffplanner/schedule.users.html',
                    controller : 'scheduleUsersController'
                }
            }
        })
        .state("schedule.shift", {
            url : "/shift/:shiftId",
            views : {
                'schedule@schedule' : {
                    templateUrl : 'templates/app-staffplanner/schedule.shift-details.html',
                    controller : 'shiftDetailsController'
                }
            }
        })
        .state("schedule.shift.users", {
            parent : 'schedule.shift',
            url : "/users/:action?",
            views : {
                'schedule@schedule' : {
                    templateUrl : 'templates/app-staffplanner/schedule.users.html',
                    controller : 'scheduleUsersController'
                }
            }
        })
        .state("restaurant", {
            url : '/restaurant/:companyId',
            abstract : true,
            templateUrl : 'templates/app-restaurant/restaurant.html',
            controller : 'restaurantController'
        })
        .state("restaurant.info", {
            url : '/',
            views : {
                'restaurant-info-view@restaurant' : {
                    templateUrl : 'templates/app-restaurant/restaurant-info.html'
                }
            }
        })
        .state("restaurant.widget", {
            url : '/',
            views : {
                'restaurant-info-view@restaurant' : {
                    templateUrl : 'templates/app-restaurant/restaurant-widget.html'
                }
            }
        })
        .state("restaurant.jobs", {
            url : '/',
            views : {
                'restaurant-info-view@restaurant' : {
                    templateUrl : 'templates/app-restaurant/restaurant-jobs.html'
                }
            }
        })
        .state("restaurant.messages", {
            url : '/',
            views : {
                'restaurant-info-view@restaurant' : {
                    templateUrl : 'templates/app-restaurant/restaurant-messages.html'
                }
            }
        })
        .state("restaurant.mentions", {
            url : '/',
            views : {
                'restaurant-info-view@restaurant' : {
                    templateUrl : 'templates/mentions.html'
                }
            }
        })
        .state("profile", {
            url : '/profile',
            templateUrl : "templates/app-profile/profile.html",
            abstract : true,
            controller : 'profileBaseController'

        })

        .state("profile.info", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.self.info.html',
                    controller : 'profileController'
                }
            }
        })
        .state("profile.wall", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.wall.html',
                    controller : 'wallController'
                }
            }
        })
        .state("profile.restaurants", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.restaurants.html',
                    controller : 'profileRestaurantsController'
                }
            }
        })
        .state("profile.edit", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.self.edit.html',
                    controller : 'profileEditController'
                }
            }
        })
        .state("profile.change-password", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.self.password.html',
                    controller : 'profileEditPasswordController'
                }
            }
        })
        .state("profile.mentions", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/mentions.html'
                }
            }
        })
        .state("profile.schedule", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.self.schedule.html',
                    controller : 'profileScheduleController'
                }
            }
        })
        .state("profile.public", {
            url : '/user/:userId',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/app-profile/profile.public.html',
                    controller : 'publicProfileController'
                }
            }
        })
        .state("profile.public.mentions", {
            url : '/',
            views : {
                'profile-inner@profile': {
                    templateUrl : 'templates/mentions.html'
                }
            }
        })

        .state("guide", {
            url : '/guide',
            abstract : true,
            templateUrl : 'templates/app-guide/guide.html',
            controller : 'industryGuideController'
        })
        .state("guide.categories", {
            url : '/',
            views : {
                'guide@guide' : {
                    templateUrl : 'templates/app-guide/guide.categories.html'
                }
            }
        })
        .state("guide.category", {
            url : '/category/:catId',
            views : {
                'guide@guide' : {
                    templateUrl : 'templates/app-guide/guide.category.html',
                    controller : 'industryGuideCategoryController'
                }
            }
        })
        .state("guide.company", {
            url : '/company/:companyId',
            views : {
                'guide@guide': {
                    templateUrl: 'templates/app-guide/guide.company.html',
                    controller: 'industryGuideCompanyController'
                }
            }
        })
        .state("logout", {
            url : "/logout",
            template : "",
            controller : 'logoutController'
        })

}]);
/**
 * Created by IMRANEXPT on 10/15/15.
 */

var deviceID;
FoodFriendsApp
    .run(
    [          '$rootScope', '$state', '$stateParams','FFApi','$window','$openFB',
        function ($rootScope,   $state,   $stateParams, FFApi,$window, $openFB) {
            FastClick.attach(document.body);
            FFApi.setLang();
            if(FFApi.lang == 'en')
                moment.locale('en');
            else
                moment.locale('sv');
            moment.tz.setDefault("Europe/Stockholm");
            var sidebar_visible = false;
            var footer_visible = false;
            var header_visible = false;
            SocialGap.Linkedin_ChangeSettings('816dvg8qdujozy', '2vGPdsQm1K8HYzkv', 'http://localhost/oauthcallback.html', 'r_basicprofile,r_emailaddress');
            $openFB.init( {appId: '496651573876675'} );
            //$openFB.init( {appId: '1704566406483736'} );
            //$openFB.init( {appId: '30982464549'} );
            var right_sidebar = false;
            $rootScope.isLoading = function(){ return FFApi.isAjax(); };
            $rootScope.previousState = {url : '', params : {}};
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            var my_info = FFApi.getData("myInfo", false);
            var my_work = FFApi.getData("my_work", false);
            $rootScope.myInfo = my_info == false ? {display_name : '', work_title : '', work_company : '', member_picture : ''} : JSON.parse(my_info);
            $rootScope.my_work = my_work == false ? [] : my_work;
            $rootScope.setInfo = function(info){
                FFApi.setData("myInfo", JSON.stringify(info));
                $rootScope.myInfo = info;
            };
            $rootScope.my_work = JSON.parse(my_work);

            $rootScope.sidebarOpen = function(){return sidebar_visible;};
            $rootScope.sidebarROpen = function(){return right_sidebar;};
            $rootScope.toggleSidebar = function(e){
                if(!_.isUndefined(e)) e.stopPropagation();
                    sidebar_visible = !sidebar_visible;
            };
            $rootScope.hideSidebar = function(e){
                e.stopPropagation();
                if(sidebar_visible == true) sidebar_visible = false;
            };
            $rootScope.setFooter = function(footer){
                footer_visible = footer;
            };
            $rootScope.hasFooter = function(){ return footer_visible; };
            $rootScope.setHeader = function(header){
                header_visible = header;
            };
            $rootScope.hasHeader = function(){ return header_visible; };
            $rootScope.$on("$stateChangeSuccess", function(c, p){
                sidebar_visible = false;
                footer_visible = false;
                header_visible = false;

            });

            $rootScope.goBack = function(){
                $window.history.back(true);
            };
            $rootScope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams){
                console.log("Started");
                    //event.preventDefault();
                    //$rootScope.previousState = {url : fromState.name, params : fromParams};
                });
            $rootScope.getLang = function(key){
                return FFApi.getLang(key);
            };
            $rootScope.$on("$viewContentLoaded", function(event, viewLog){
                var elm = document.querySelector(".view-animation");
                if(elm)
                    elm.addEventListener("animationend", function(){
                        $rootScope.$broadcast("animationEnded");
                        $rootScope.$apply();
                    });
                var $holder = angular.element(document.querySelector("section.app-content"));
                $holder.off('scroll');
                $holder.on('scroll', function(e){
                    var _offTop = $holder[0].scrollTop;
                    var _offPos = $holder[0].getBoundingClientRect().top;
                    [].slice.call(document.querySelectorAll('.fixed-to-top')).forEach(function (el) {
                        if(_offTop > 0){
                            el.style.top = (_offTop) + "px";
                        }else{
                            el.style.top = "";
                        }
                    });
                });
            });
            $rootScope.setLastPage = function(){
                $rootScope.previousState.url = $state.current.name;
                $rootScope.previousState.params = $stateParams;
            };
            $rootScope.gotoPreviousState = function(){
                if($rootScope.previousState.url == '')
                    $state.go('schedule.shifts');
                else
                    $state.go($rootScope.previousState.url, $rootScope.previousState.params);
            }

        }
    ]
);

if(typeof(cordova) === 'undefined'){
    angular.element(document).ready(_bootStrap);
}else {
    document.addEventListener('deviceready', function () {
        StatusBar.hide();
        //cordova.plugins.Keyboard.disableScroll(true);
        _bootStrap();
        cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
    });
    document.addEventListener('resume', function () {
        //window.location.reload();
    });
}
function _bootStrap(){
    deviceID = getDeviceUUID();
    angular.bootstrap(document, ['FoodFriends']);
}
//document.addEventListener('deviceready', function(){

    function _p8(s) {
        var p = (Math.random().toString(16)+"000000000").substr(2,8);
        return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
    }
    function getDeviceUUID(mobile){
        if(typeof(mobile) === 'undefined'){
            var presaved = window.localStorage.getItem("uuid");
            var generated_key = _p8() + _p8(true) + _p8(true) + _p8();
            if(!presaved) {
                presaved = generated_key;
                window.localStorage.setItem("uuid", presaved);
            }
            return presaved;
        }
        if(typeof window.StatusBar !== 'undefined')
            StatusBar.hide();
        return device.uuid;
    }
/**
 * Created by imranexpt on 25/10/2016.
 */
FoodFriendsApp.controller("foodfriendsBaseCtrl", function($scope, $state, FFApi, $rootScope) {
    $scope.blogpost = null;
    $scope.setBlogPost = function(blog){
        $scope.blogpost = blog;
    };
    $scope.thumb = '';
    $scope.getHeader = function(){
        return 'templates/app-foodfriends/headers/' + $state.current.name.replace('foodfriends.','') + '.html';
    };
    if(!FFApi.hasAuth()) {
        $state.go('splash');
    }
    $scope.header_params = {};
    $scope.setHeaderParams = function(params, copy){
        if(_.has(params, 'thumb')) {
            $scope.thumb = params.thumb;
        }
        $scope.header_params = _.isUndefined(copy) ? angular.copy(params) : params;
    };
    $scope.getHeaderTitle = function(){ return headerTitle; };
    var footer = has_header = true;
    var headerClass = '';
    $scope.setFooter = function(a){
        footer = a;
    };
    $scope.headerClass = function(){
        return headerClass;
    };
    $scope.hasHeader = function(){ return has_header; };
    $scope.setHeaderClass = function(cls){ headerClass = cls; };
    $scope.getFooter = function(){ return footer; };
    $scope.setHeader = function(t){ has_header = t;};
    $rootScope.$on('$viewContentLoading',
        function(event, viewConfig){
                footer = true;
                has_header = true;
                $scope.header_params = {};
        });
    $scope.checkScroll = function(){
         $scope.$broadcast("SCROLL_EVENT");
    };
    $scope._toggleJobsForm = function(){
        $scope.$broadcast("JOBSFORMTOGGLE");
    };
});
/**
 * Created by imranexpt on 25/10/2016.
 */
FoodFriendsApp.controller("foodfriendsHomeCtrl", function($scope, $state, FFApi, $timeout, jobPost, $document) {
    var a = 10;
    FFApi.search_query = '';
    $scope.thumb = '';
    FFApi.search_tab = 'restaurant';
    var n = moment().unix();
    $scope.statistics = FFApi.homeCache;
    $scope.news = [];
    var apiLoaded = false;
    $scope.setHeaderParams({'thumb' : ''});
    $scope.group_message = false;
    var mention_timeout = null;
    $scope.latest_row = {restaurant : false, news : false, member : false};

    var offset = 2;
    var scrollInProgress = false;
    var uploadedPostImage = document.getElementById('upload-pic-thumb');
    $scope._post = {text : '', picture : '', alert : false, mention : {users : [], restaurant : 0, text : ''}};
    $scope.publishPost = publishPost;
    $scope.resetPostPicture = function(){
        $scope._post.picture = '';
        uploadedPostImage.src = '';
        uploadedPostImage.classList.add('hide');
    };
    $scope.hasPostPicture = function(){
        return _.size($scope._post.picture) >= 10;
    };
    $scope.wallPosts = [];
    $scope.mini_search = {people : false, restaurants : false, index : 0, text : '', original : ''};
    $scope.$watch("_post.text", function(postText){
        $scope.mini_search.original = $scope._post.text;
        if(postText.lastIndexOf('@') >= 0 && !($scope._post.mention.users.length > 0 && $scope._post.mention.restaurant > 0)) {
            var search_string = postText.substring(postText.lastIndexOf('@')).substring(1);
            if(search_string.indexOf(' ') >= 0){
                $timeout.cancel(mention_timeout);
                return;
            }
            //    search_string = search_string.substring(0,search_string.indexOf(' '));
                if (mention_timeout !== null)
                    $timeout.cancel(mention_timeout);
                if(search_string.length >= 3) {
                    var indx = postText.lastIndexOf('@');
                    mention_timeout = $timeout(function () {
                        mentionSearch(search_string, indx)
                    }, 500);
                }
        }
    });
    $scope.selectSearchResultForPost = function(type, obj){
        //console.log(type, obj, '@'+ $scope.mini_search.text);
        if(type == 'restaurant') {
            $scope._post.mention.restaurant = obj.company_id;
        }else{
            $scope._post.mention.users.push(obj.custid);
        }
        $scope._post.text = $scope._post.text.replace('@' + $scope.mini_search.text,(type == 'restaurant' ? obj.restaurant_name : (obj.first_name + ' ' + obj.last_name)));
        $scope.mini_search = {people : false, restaurants : false, index : 0, text : '', original : ''};

        document.querySelector('#post-text-box').focus();
    };
    function mentionSearch(text, kIndex){
        $scope.mini_search = {people : false, restaurants : false, index : 0, text : ''};
        FFApi.callAPI("searchRestaurants", {query : text, type : 'both', 'limit' : 10}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.mini_search = {people : response.people, restaurants : response.restaurants, index : kIndex, text : text};
            document.querySelector('#post-text-box').blur();
            //console.log(response.restaurants);
        });
    }

    function publishPost(e){
            FFApi.callAPI("publishWallPost", {post: $scope._post}).success(function (response) {
                if (!FFApi.handleResponse(response)) return false;
                //$scope.statistics.wall.unshift(response.post);
                $scope.wallPosts.push(response.post);
                //$scope.$apply();
                $scope._post = {text: '', picture: '', alert: true, mention: {users: [], restaurant: 0}};
                $scope.statistics.last_accessed = moment.unix();
                var firstPost = document.querySelector('.wall-post');
                var stop = firstPost ? firstPost.getBoundingClientRect().top : 100;
                var appContent = document.querySelector('.app-content');
                uploadedPostImage.src = '';
                uploadedPostImage.classList.add("hide");
                if (appContent)
                    appContent.scrollTop = stop - 100;
                $timeout(function () {
                    $scope._post.alert = false
                }, 2000);
                //document.querySelector(".")
            });
    }

    function incScrollTop(){
        console.log("Scrolling Now");
        //var firstPost = document.querySelector('.wall-post');
        //var appContent = document.querySelector('.app-content');
        //var st = 0;
        //var stop = firstPost.getBoundingClientRect().top;


    }
    $scope.uploadPostPicture = function(){
        if(typeof(navigator.camera) == 'undefined') return;
        $timeout(function(){
            navigator.camera.getPicture(function(imageData){
                    imageData = 'data:image/jpeg;base64,' + imageData;
                    $scope._post.picture = imageData;
                    uploadedPostImage.src = imageData;
                    uploadedPostImage.classList.remove("hide");
                    $scope.$apply();
                }, function(message){

                },
                {
                    quality: 80,
                    destinationType: Camera.DestinationType.DATA_URL,
                    sourceType : Camera.PictureSourceType.PHOTOLIBRARY,
                    encodingType: Camera.EncodingType.JPEG,
                    allowEdit : true,
                    targetWidth: 300,
                    targetHeight: 300
                }
            );
        },0);
    };
    function _addHomeBoxes(response){
        $scope.latest_row = {restaurant : false, news : false, member : false};
        if(response.wall) {
            for (var j in response.wall) {
                response.wall[j]['wall_type'] = 'wall_post';
                $scope.wallPosts.push(response.wall[j]);
            }
        }
        /*if(response.restaurant && !_.find($scope.wallPosts, {wall_type : 'restaurant', ID : response.restaurant.ID})){
            response.restaurant['wall_type'] = 'restaurant';
            $scope.wallPosts.push(response.restaurant);
        }*/
        if(response.restaurant){
            $scope.latest_row.restaurant = angular.copy(response.restaurant);
        }
        if(response.news){
            $scope.latest_row.news = angular.copy(response.news);
        }
        if(response.job_moved)
            $scope.latest_row.member = angular.copy(response.job_moved);
        if(response.jobs) {
            for (var j in response.jobs) {
                response.jobs[j]['wall_type'] = 'job_post';
                $scope.wallPosts.push(new jobPost(response.jobs[j]));
            }
        }
        $scope.statistics.all_jobs = response.jobs;
        //$scope.statistics.jobs = response.new_jobs;
        //$scope.statistics.restaurant = response.restaurant;
        //$scope.statistics.blog = response.blog;
        //$scope.statistics.wall = response.wall;
        if(response.blog) {
            for (var j in response.blog) {
                response.blog[j]['wall_type'] = 'blog_post';
                $scope.wallPosts.push(response.blog[j]);
            }
        }

        //console.log($scope.wallPosts);

    }
    function initHome(){
        //console.log(n-FFApi.homeCache.last_accessed);
        if((n-FFApi.homeCache.last_accessed) > 300) {
            $scope.group_message = false;
            $scope.statistics = {messages : 0, members : 0, jobs : 0, restaurants : 0, member_list : [], all_jobs : [], restaurant : {}, member_work : true, last_accessed : 0, blog : [], wall : []};
            FFApi.callAPI('getHomeBoxes', {}).success(function (response) {
                if (!FFApi.handleResponse(response)) return false;
                $scope.setHeaderParams({messageCount : response.messages, thumb : (response.thumb || '')});
                $scope.statistics.member_list = response.members;
                $scope.statistics.members = _.size(response.members);
                $scope.group_message = response.group_message;
                $scope.statistics.member_work = response.work;
                if(response.restaurant)
                    response.restaurant['wall_type'] = 'restaurant';
                $scope.wallPosts.push(response.restaurant);
                _addHomeBoxes(response);
                $scope.statistics.last_accessed = moment.unix();
            });
        }
    }
    $scope.hasGroupMessages = function(){ return $scope.group_message !== false; };
    $scope.$on("$viewContentLoaded", initHome);
    $scope.openPost = function(post){
        if(post.category == 'news'){
            if(typeof(cordova) !== 'undefined')
                cordova.InAppBrowser.open(post.post_url, '_blank', 'location=yes');
            else
                window.open(post.post_url,'');
        }else{
            $scope.setBlogPost(post);
            $state.go('foodfriends.blog');
        }
    };
    $scope.$on("SCROLL_EVENT", function(){
        if(scrollInProgress == true){
            return true;
        }
        scrollInProgress = true;
        FFApi.callAPI('getHomeBoxes', {offset : offset}).success(function (response) {
            if(!FFApi.handleResponse(response)) return false;
            offset++;
            scrollInProgress = false;
            _addHomeBoxes(response);
            /*for(var x in response.blog){
                response.blog[x]['wall_type'] = 'blog_post';
                $scope.wallPosts.push(response.blog[x]);
            }
            for(var x in response.wall){
                response.wall[x]['wall_type'] = 'wall_post';
                $scope.wallPosts.push(response.wall[x]);
            }*/

        });
    });
    $scope.getWallPosts = function(){
        if(offset > 2) {
            console.log("No sorting");
            return $scope.wallPosts;
        }
        return _.sortBy($scope.wallPosts, function(wp){
            return _.isNull(wp.date_publish) ? 0 :  moment(wp.date_publish).unix();
        }).reverse();
    }

});

/**
 * Created by imranexpt on 09/06/2017.
 */
FoodFriendsApp.controller("postsListCtrl", function($scope, $stateParams, FFApi,$state) {
    $scope.postType = _.has($stateParams, 'type') ? $stateParams.type : '';
    $scope.$on("$viewContentLoaded", initList);
    $scope.restaurants = [];
    $scope.news = [];
    var offset = 2;
    var scrollInProgress = false;
    $scope.jobs = [];
    function initList(){
        $scope.setHeader(true);
        getPostsList();
        var title = $scope.postType === 'news' ? 'News' : $scope.postType === 'restaurants' ? 'Restaurants' : '';
        $scope.setHeaderParams({'title' : title});
    }

    function getPostsList(_offset){
        var _of = _.isUndefined(_offset) ? 1 : _offset;
        if(_of <= 1) {
            $scope.restaurants = [];
            $scope.news = [];
            $scope.jobs = [];
        }
        FFApi.callAPI('getPostsList', {type : $scope.postType, offset : _of}).success(function (response) {
            if (!FFApi.handleResponse(response)) return false;
            if(_.has(response,$scope.postType))
                if(_of > 1) {
                    _.forEach(response[$scope.postType], function(p) {
                        $scope[$scope.postType].push(p);
                    });
                    offset++;
                    scrollInProgress = false;
                }else{
                    $scope[$scope.postType] = response[$scope.postType];
                }
        });
    }
    $scope.openPost = function(post){
        if(post.category === 'news'){
            if(typeof(cordova) !== 'undefined')
                cordova.InAppBrowser.open(post.post_url, '_blank', 'location=yes');
            else
                window.open(post.post_url,'');
        }else{
            $scope.setBlogPost(post);
            $state.go('foodfriends.blog');
        }
    };
    $scope.$on("SCROLL_EVENT", function(){
        if(scrollInProgress === true){
            return true;
        }
        scrollInProgress = true;
        getPostsList(offset);
    });
});
/**
 * Created by imranexpt on 26/10/2016.
 */
FoodFriendsApp.controller("foodfriendsSearchCtrl", function($scope, $state, FFApi, $timeout, $stateParams, WorkExperience) {
    $scope.search = {query : (FFApi.search_query || ''), page : 1, restaurants : [], people : [], tab : (FFApi.search_tab || 'restaurant'), _add_new_rest : false, _new_restaurant : ''};
    $scope.search_interval = null;
    $scope.standalone_search = _.has($stateParams,'standalone') && $stateParams.standalone == 'yes';
    $scope.setHeaderParams({search : $scope.search, standalone : $scope.standalone_search}, false);
    $scope.work_list = FFApi.work_list;
    $scope.lang = FFApi.lang;
    $scope._work = new WorkExperience();
    $scope._work._addRest = true;
    var offset = 2;
    var scrollInProgress = false;

    function initSearch(_offset){
        var _of = _.isUndefined(_offset) ? 1 : _offset;
        if(_of <= 1) {
            // empty here
        }
        FFApi.callAPI("searchRestaurants", {query : $scope.search.query, page : $scope.search.page, type : 'both', 'limit_restaurant' : 3, offset : _of}).success(function(response){
           if(!FFApi.handleResponse(response)) return false;
           if(_of > 1){
               if(_.has(response, 'people')){
                   _.forEach(response.people, function(p){
                       $scope.search.people.push(p);
                   })
               }
               offset++;
               scrollInProgress = false;
           }else {
               $scope._work.restaurant = $scope.search.query;
               $scope.search.restaurants = response.restaurants;
               $scope.search.people = response.people;
               $scope.search._new_restaurant = angular.copy($scope.search.query);
           }

            //console.log(response.restaurants);
        });
    }
    $scope.toggleAddRestaurant = function(){
        $scope.search._add_new_rest = !$scope.search._add_new_rest;
    };
    $scope._newRestaurant = function(){
        FFApi.callAPI("addNewRestaurant", {restaurant : $scope.search._new_restaurant}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $state.go('restaurant.info', {companyId : response.company_id});
        });
        //addNewRestaurant
    };
    $scope.addWorkExperience = function(){
        $scope._work.save().then(function(response){
            if(!FFApi.handleResponse(response.data)) return false;
            $state.go('restaurant.info',{companyId : response.data.id});
        });
    };

    //$scope.$on("$")
    $scope.$on("$viewContentLoaded", initSearch());
    $scope.selectRestaurant = function(restaurant){
        if($scope.standalone_search == true){
            FFApi.sharedData.restaurant = restaurant;
        }else {
            $state.go('restaurant.info', {companyId: restaurant.company_id});
        }
    };

    $scope.$watch("search.query", function(query){
        if(query.length >= 2 && query !== FFApi.search_query){
            FFApi.search_query = $scope.search.query;
            FFApi.search_tab = $scope.search.tab;
            $timeout.cancel($scope.search_interval);
            $scope.search_interval = $timeout(initSearch, 500);
        }
        if(query === ''){
            $timeout.cancel($scope.search_interval);
            $scope.search.restaurants = [];
            $scope.search.people = [];
            FFApi.search_tab = '';
            $scope.search.query = '';
        }
    });

    $scope.$on("SCROLL_EVENT", function(){
        if(scrollInProgress === true){
            return true;
        }
        scrollInProgress = true;
        initSearch(offset);
    });
});
/**
 * Created by imranexpt on 26/10/2016.
 */
FoodFriendsApp.controller("foodfriendsJobsCtrl", function($scope, $stateParams, FFApi, $rootScope) {
    $scope.jobId = _.has($stateParams, 'jobId') ? _.parseInt($stateParams.jobId) : 0;
    $scope.jobDetails = {replytext : ''};
    $scope.restaurant = {};
    $scope.jobs = [];
    $scope.addJob = {opened : false, comments : ''};
    $scope.jobLoaded = false;
    if($scope.jobId > 0) {
        $scope.setFooter(false);

    }else{
      
    }
    $scope.toggleJobsForm = function(){
        $scope.addJob.opened = !$scope.addJob.opened;
    };
    $scope.setHeaderParams({jobId : $scope.jobId});
    function initJobs(){
        if($scope.jobId > 0) {
            $scope.setHeader(false);
            FFApi.callAPI('getJob', {jobId : $scope.jobId}).success(function (response) {
                if (!FFApi.handleResponse(response)) return false;
                $scope.jobLoaded = true;
                $scope.jobDetails = response.job;
                $scope.restaurant = response.restaurant;
                $scope.jobDetails.replytext = '';
                $scope.setHeaderParams({title : $scope.jobDetails.title, jobId : $scope.jobId});

            });
        }else{
            FFApi.callAPI('getJobs', {}).success(function (response) {
                if (!FFApi.handleResponse(response)) return false;
                $scope.jobs = response.jobs;
            });
        }
    }
    $scope.$on("$viewContentLoaded", initJobs);
    $scope.postJobReply = function(){
        if(_.size($scope.jobDetails.replytext) <= 3){
            alert("Please add a text");
            return false;
        }
        FFApi.callAPI("postJobReply", {jobId : $scope.jobDetails.id, text : $scope.jobDetails.replytext}).success(function (response){
            if (!FFApi.handleResponse(response)) return false;
            FFApi.alert("Your reply to this job has been recieved!");
        });
    };
    $scope.$on("JOBSFORMTOGGLE", function(){
        $scope.addJob.opened = !$scope.addJob.opened;
    });
    $scope.sendJobRequest = function(){
        FFApi.callAPI("sendJobRequest", {comments : $scope.addJob.comments}).success(function (response){
            if(!FFApi.handleResponse(response)) return false;
            FFApi.alert(FFApi.getLang('job_req_alert'), FFApi.getLang('title_job_req'));
            $scope.addJob.opened = false;
        })  ;
    };
});

/**
 * Created by imranexpt on 10/11/2016.
 */
FoodFriendsApp.controller("foodFriendsBlogCtrl", function($scope, $stateParams, FFApi) {

});
FoodFriendsApp.controller("staticCtrl", function ($scope,$state, $stateParams, FFApi) {
    var pageHtml = '';
    $scope.pagesHome = function () {
        return _.isUndefined($stateParams.page) || $stateParams.page == '';
    };
    $scope.page = $stateParams.page;
    $scope.setHeaderParams({
        'type': $scope.pagesHome() ? 'home' : 'page',
        'page': $stateParams.page,
        'title' : getTitle($stateParams.page)
    });
    function getTitle(page){
        return FFApi.getLang('pages_' + page);
    }
    $scope.setLanguage = function(lang){
        FFApi.setLang(lang);
        $state.reload();
    };
    $scope.getLanguage = function(){ return FFApi.lang; };

    function loadPage(page){
        FFApi.callAPI("getStaticPage", {page : page}).success(function(response){
           if(!FFApi.handleResponse(response)) {
               $state.go('foodfriends.pages', {page : ''});
               return;
           }
           pageHtml = response.page;
        });
    }
    if(!$scope.pagesHome())
        loadPage($stateParams.page);
    $scope.getPage = function(){

        return pageHtml;
    };


});
/**
 * Created by imranexpt on 26/10/2016.
 */
FoodFriendsApp.controller("restaurantsCtrl", function($scope, $stateParams, FFApi) {
    $scope.setHeaderClass('large-header');


});

/**
 * Created by IMRANEXPT on 10/17/15.
 */
FoodFriendsApp.controller("scheduleBase", function($scope, $state, FFApi, $stateParams, $sce){
    $scope.schedule = {};
    $scope.schedule = FFApi.getActiveSchedule();
    $scope.schedules = FFApi.getData("schedules") || "";
    $scope.types = {};

    $scope.gotoUserProfile = function(userid){
        if(userid && userid > 0){
            $state.go('profile.public', {userId : userid});
        }
    };

    if(_.isEmpty($scope.schedules))
        $scope.schedules = [];
    else
        $scope.schedules = JSON.parse($scope.schedules);
    var filters = FFApi.getData("schedule_filters");
    filters = _.size(filters) > 2 ? JSON.parse(filters) : {shifts : 'all' , sort : 'name', type : 0};
    $scope.schedule_filters = {popup : false, shifts : filters.shifts, sort : filters.sort, type : filters.type || 0};
    //$scope.setHeader('templates/schedule.header.html');
    if(!$scope.schedule){
    }
    var _filterSchedule = false;
    $scope.filterSchedule = function(e,hide){
        e.stopPropagation();
        if(!_.isUndefined(hide))
            _filterSchedule = false;
        else
        _filterSchedule = !_filterSchedule;
    };
    $scope.selectSchedule = function(schedule_id){
        FFApi.setData("schedule_ID", schedule_id);
        FFApi.setData("schedule_filters", "");
        $state.reload();

    };
    $scope.shift = false;
    $scope.add_shift = {};
    $scope.date = moment().format('YYYY-MM-DD');
    $scope.isScheduleFilter = function(){
        return _filterSchedule === true;
    };
    $scope.getWeekRange = function(objs){
        var md = moment($scope.date);
        if(_.isUndefined(objs))
            return md.day(1).format("D MMM") + '   -   ' + md.day(7).format("D MMM");
        return [md.day(1), md.day(7)];
    };
    $scope.childState = function(what){
        if(_.isArray(what)) {
            for (var x in what)
                if ($state.is('schedule.' + what[x])) return true;
            return false;
        }
        //if(what == 'shift' && $state.includes('schedule.shift')) return true;
        return $state.is('schedule.' + what);
    };
    $scope.isShiftPage = function(){
        if($state.includes('schedule.shift') ) return false;
        return true;
    };
    $scope.nextWeek = function(){
        var md = moment($scope.date);
        md.day(7);
        md.day(1);
        $state.go($state.current.name, {'date' : md.format('YYYY-MM-DD')}, {reload : false});
    };
    $scope.prevWeek = function(){
        var md = moment($scope.date);
        md.day(-7);
        md.day(1);
        $state.go($state.current.name, {'date' : md.format('YYYY-MM-DD')}, {reload : false});
    };
    $scope.setDate = function(d){
        $scope.date = d;
    };
    $scope.saveFilters = function(){
        var filters =   JSON.stringify({shifts : $scope.schedule_filters.shifts , sort : $scope.schedule_filters.sort, type : $scope.schedule_filters.type});
        FFApi.setData("schedule_filters", filters);
        $scope.schedule_filters.popup = false;
        $state.reload();
    };
    $scope.getScheduleImage = function(){
        return $scope.schedule.picture ? $sce.trustAsResourceUrl('http://www.foodfriends.com/static/images/company/' + $scope.schedule.picture)
                : $sce.trustAsResourceUrl('images/restaurant_blank.jpg');
    };
    $scope.setShift = function(shift){
        $scope.shift = shift;
    };
    $scope.isUsersPage = function(){
        return $scope.childState(['shift.users','shift.swap','shift.invite','add-shift.users']);
    };
    $scope.goToLink = function(link){
        var _reload = false;
        if($state.current.name == link) _reload = true;
        $state.go(link, {}, {reload : _reload});
        //$state.transitionTo(link, {}, { reload: false, inherit: false, notify: true });
    };

});
FoodFriendsApp.controller("scheduleController", function($scope, $state, FFApi, $stateParams){
    $scope.setLastPage();
    if(_.has($stateParams,'date') && !_.isEmpty($stateParams.date)){
        $scope.setDate($stateParams.date);
    }else{
        //$scope.setDate(moment().format("YYYY-MM-DD"));
    }
    $scope.loadShift = function(shift){
        if($scope.schedule.type == 'admin' || shift.isMine === true){
            $state.go('schedule.shift', {shiftId : shift.shift_ID});
        }else{
            return false;
        }
    };
    var time_slots = [];
    $scope.shifts = [];
    $scope._selectedDate = moment($scope.date).format('YYYY-MM-DD');
    $scope.weekdays = [];
    var _d = moment($scope.date);
    for(var i=1; i<=7; i++){
        $scope.weekdays.push(moment(_d).isoWeekday(i));
    }
    var min_time = moment().hour(5).minute(0).unix();
    var max_time = moment().hour(11).minute(59).unix();


    $scope.setSelectedDate = function(d){
        $scope._selectedDate = d.format('YYYY-MM-DD');
        $scope.setDate(angular.copy($scope._selectedDate));
        setTimeSlots();
    };

    $scope.getShiftClass = function(shift){
      if(shift.is_cancelled === true) return 'red';
      if(shift.shift_type == 'unassigned') return 'green';
      return 'blue';
    };

    function loadSchedule(){
        var schedule_ID = FFApi.getData("schedule_ID");
        if(!schedule_ID) {
            FFApi.alert("You do not have any active schedule. ");
            $state.go("messages.list");
            return false;
        }
        FFApi.callAPI("getFullSchedule", {schedule_ID : schedule_ID, is_weekly : true, date : $scope.date}).success(function(response){
            if(!FFApi.handleResponse(response)){
                return false;
            }else{
                if(!_.isEmpty(response.shifts))
                $scope.shifts = applyFilters(response.shifts);
                $scope.types = response.types;
                setTimeSlots();
            }
        });
    }
    $scope.findSlotPosition = function(start, end){
        var min_time = _.first(time_slots);
        var max_time = _.last(time_slots);
        if(min_time > 0 && max_time > 0){
            var lleft = (((start - min_time)/(max_time - min_time)) * 100);
            var lright = (((end - min_time)/(max_time - min_time)) * 100);
            if(lleft + lright > 100)
                lright = lright - ((lleft+lright) - 100);
            //console.log(lleft, lright);
            lright = Math.abs(lright);
            return {left : lleft + '%', width : lright + '%'}
        }
        return {left : 0, width : 0};
    };
    function setTimeSlots(){
        var shifts = _.filter($scope.shifts, {date : $scope._selectedDate});

        var min_time = moment().hours(5).unix();
        var max_time = moment().hours(23).unix();
        if(_.size(shifts) > 0){
            var s_start_list = [];
            var s_end_list = [];
            _.forEach(shifts, function(s){
                s_start_list.push(s.start);
                s_end_list.push(s.end < s.start ? (s.end+(3600*24)) : s.end);
            });
            var _mi = _.min(s_start_list);
            var _mx = _.max(s_end_list);
            if(_mx < _mi) _mx = _mx+(3600*24);
            min_time = _mi;
            max_time = _mx;
        }
        time_slots = _.range(min_time,max_time,3600);
    }
    $scope.getTimeSlot = function(){
        return time_slots;
    };
    $scope.filterShifts = function(){
            var shifts = _.filter($scope.shifts, {date : $scope._selectedDate});
            if($scope.schedule_filters.shifts == 'all'){
                //shifts = _.filter(shifts, {})
            }else if($scope.schedule_filters.shifts == 'unassigned'){
                shifts = _.filter(shifts, {shift_type : 'unassigned'});
            }
            if($scope.schedule_filters.type !== 0){
                shifts = _.filter(shifts, {type_id : _.parseInt($scope.schedule_filters.type)});

            }
            switch($scope.schedule_filters.sort){
                case 'name':
                    shifts = _.sortByOrder(shifts, function(s){ return s.user_display_name.toLowerCase(); });
                    break;
                case 'type':
                    shifts = _.sortByOrder(shifts, function(s){ return s.type_name.toLowerCase(); });
                    break;
                case 'time':
                    shifts = _.sortByOrder(shifts, ['start'], 'asc');
                    break;
            }
            return shifts;
    };
    loadSchedule();
    $scope.orderShifts = function(){
        return function(shifts){
            return shifts;
        };
    };
    function applyFilters(shifts){

        switch($scope.schedule_filters.sort){
            case 'name':
                shifts = _.sortByOrder(shifts, ['user_display_name'], 'ASC');
                break;
        }
        return shifts;
    }

});


FoodFriendsApp.controller('scheduleUsersController', function($scope, $state, FFApi, $stateParams){
    $scope.users = [];
    $scope.user_action = $stateParams.action || "none";


    if($scope.user_action == 'none'){
        $state.go('schedule.shifts');
        return;
    }
        FFApi.callAPI("getScheduleUsers", {schedule_ID : $scope.schedule.ID, date : $scope.date}).success(function(response){
            if(!FFApi.handleResponse(response)){
                $state.go('schedule.shifts');
            }else{
                $scope.users = filterUsers(response.users);
            }
        });
    $scope.selectUser = function(user){
        user.selected = !user.selected;
        if($scope.user_action == 'assign' || $scope.user_action == 'swap'){
            $scope.applyUser();
        }
    };

    $scope.applyUser = function(){
        switch($scope.user_action){
            case 'assign':
                var user = _.first(findSelected());
                if(user)
                    if($scope.previousState.url == 'schedule.add-shift') {
                        FFApi.setSharedData([user]);
                        $state.go('schedule.add-shift');
                    }else {
                        $scope.shift.assign(user.ID).then(function (response) {
                            $state.go('schedule.shift', $stateParams);
                        });
                    }

                break;
            case 'invite':
                if($scope.previousState.url == 'schedule.add-shift') {
                    FFApi.setSharedData(findSelected());
                    //$scope.add_shift.invited_users = findSelected();
                    $state.go('schedule.add-shift');
                }else{

                }

                break;
            case 'swap':
                var user = _.first(findSelected());
                if(user)
                $scope.shift.requestSwap(user.ID).then(function(response){
                    $state.go('schedule.shift', {shiftId : $scope.shift.id});
                });
                break;
        }
    };
    $scope.hasSelected = function(getlen){
        var len = _.size(findSelected());
        return !_.isUndefined(getlen) ? len > 0 ? len : 0 : len > 0;
    };
    function findSelected(){
        var _users = [];
        _.forEach($scope.users, function(users, type){
            _.forEach(users, function(u){
                if(u.selected === true)
                    _users.push(u);
            });
        });
        return _users;
    }

    function filterUsers(users){
        var _users = users;
        switch($state.current.name){
            case 'schedule.shift.invite':
                _users = _.filter(users, {inv : true});

                break;
        }
        return _users;
    };
});

/**
 * Created by IMRANEXPT on 10/18/15.
 */
FoodFriendsApp.controller("myScheduleController", function($scope, $state, FFApi, $stateParams){
    $scope.setLastPage();
    if(_.has($stateParams,'date') && !_.isEmpty($stateParams.date)){
        $scope.setDate($stateParams.date);
    }else{
        $scope.setDate(moment().format("YYYY-MM-DD"));
    }
    $scope.my_shifts = {};
    var shifts = {};
    $scope.hasShifts = false;
    var week_day = moment($scope.date);
    $scope.weekdays = [];
    var _d = moment($scope.date);
    $scope.availibility = {};
    for(var i=1; i<=7; i++){
        var __d = moment(_d).isoWeekday(i);
        $scope.weekdays.push(__d);
        $scope.my_shifts[__d.format('YYYY-MM-DD')] = [];
        $scope.availibility[__d.format('YYYY-MM-DD')] = {start : '', end : '', unset : null};
    }
    loadMySchedule();
    $scope.hasAvailibility = function(dd,checkboth){
        var avl = _.has($scope.availibility, dd) ? $scope.availibility[dd] : false;
        var check = /^\d{2,}:\d{2}$/;
        if(avl)
            if(_.isUndefined(checkboth))
                return (check.test(avl.start) && check.test(avl.end));
            else
                return (check.test(avl.start) && check.test(avl.end)) ? false : (check.test(avl.start) && check.test(avl.end));
        return false;
    };
    $scope.setAvailibility = function(dd){
        if($scope.hasAvailibility(dd)){
            var avail = $scope.availibility[dd];
            var unset = avail.unset === true ? 'yes' : 'no';
            if(!avail) return false;
            avail.unset = unset == 'yes' ? true : false;
            FFApi.callAPI("setAvailibilityTime" , {date : dd, unset : unset, start : avail.start, end : avail.end}).success(function(response){
                if(!FFApi.handleResponse(response)) return false;
            });
        }
    };
    $scope.loadShift = function(shift){
       if(shift.is_cancelled === true) return false;
        if(shift.isMine === false) return false;
        $state.go('schedule.shift', {shiftId : shift.shift_ID});
    };
    function loadMySchedule(){
        FFApi.callAPI("getMySchedule" , {date : $scope.date}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            //shifts = response.shifts;
            if(_.size(response.shifts) > 0)
            _.forEach(response.shifts, function(_shifts, date){
                if(_.size(_shifts) > 0 && _.has($scope.my_shifts, date)) {
                    for (var x in _shifts) {
                        $scope.my_shifts[date].push(_shifts[x]);
                    }
                }
            });
            if(_.size(response.availibility) > 0){
                _.forEach(response.availibility, function(av, date){
                   if(_.has($scope.availibility, date)){
                       $scope.availibility[date] = av;
                   }
                });
            }
        });
    }
    $scope.confirmShift = function(shift, e){
        e.stopPropagation();
            FFApi.callAPI("confirmShift", {id : shift.shift_ID}).success(function(response){
                if(!FFApi.handleResponse(response)) return false;
                //$state.go('schedule.shift', {shiftId : shift.id});
                $scope.loadShift(shift);
            });
    };

});

/**
 * Created by IMRANEXPT on 10/17/15.
 */
FoodFriendsApp.controller("addShiftController", function($scope, $state, FFApi){
    var time_now = moment().unix();
    time_now = moment(Math.floor(time_now/300)*300 * 1000);
    if(!_.has($scope.add_shift, 'time_list') || _.size($scope.add_shift.time_list) == 0) {

        FFApi.callAPI("getScheduleTypes",{}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            if(_.has(response, 'types')) {
                $scope.schedule.types = response.types;
                for(var t in response.types){
                    $scope.add_shift.type_ID = t;
                    break;
                }
            }
        });
        $scope.add_shift.date = moment();
        $scope.add_shift.assigned_user = false;
        $scope.add_shift.invited_users = false;
        $scope.add_shift.type_ID = 0;
        $scope.add_shift.show_type_list = false;
        $scope.add_shift.time_list = [];
        $scope.add_shift.time_list.push({start : time_now.format("HH:mm"), end : time_now.add(6,'h').format('HH:mm')});
    }
    $scope.setLastPage('schedule.add-shift', {});
    var _shared_users = FFApi.getSharedData();
    if(_.isArray(_shared_users)){
        if(_.size(_shared_users) == 1) {
            $scope.add_shift.utype = 'assigned';
            $scope.add_shift.assigned_user = _.first(_shared_users);
        }else {
            $scope.add_shift.utype = 'invited';
            $scope.add_shift.invited_users = _shared_users;
        }
    }
    $scope.selectType = function(tid){
        $scope.add_shift.type_ID = tid;
        $scope.add_shift.show_type_list = false;
    };

    $scope.timepicker = false;
    $scope.timepicker_layer = false;
    $scope.timepicker_time = {h : '', m : ''};
    $scope.timepicker_prop = null;
    $scope.openTimePicker = function(time, prop){
        $scope.timepicker_prop = prop;
        $scope.timepicker = time;
        var _time = time.split(':');
        $scope.timepicker_layer = true;
        $scope.timepicker_time = {h : _time[0], m : _time[1]};
    };
    $scope.setMinutes = function(m){
        $scope.minutes = m;
        $scope.timepicker = $scope.timepicker_time.h + ':' + $scope.timepicker_time.m;
        $scope.timepicker_layer = false;
        $scope.timepicker_time = {h : '', m : ''};
    };




    $scope.addShiftBreak = function(){
        var _last = _.last($scope.add_shift.time_list);
        var _last_time = moment();
        if(_last) {
            _last_time.hours(_last.end.split(':')[0]);
            _last_time.minutes(_last.end.split(':')[1]).add(5,'m');
        }
        _last_time = moment(Math.floor(_last_time.unix()/300)*300 * 1000);

        $scope.add_shift.time_list.push({start : _last_time.format("HH:mm"), end : _last_time.add(6,'h').format('HH:mm')});
    };

    $scope._addShift = function(){
        var user_id = $scope.add_shift.assigned_user != false ? $scope.add_shift.assigned_user.ID : 0;
        var invited_users = $scope.add_shift.invited_users != false ? $scope.add_shift.invited_users : [];
        if(_.size(invited_users) > 0)
            user_id = 0;
        var query = {date : moment($scope.add_shift.date).format('YYYY-MM-DD'), schedule : $scope.schedule.ID, type : $scope.add_shift.type_ID, user : user_id, invited_users : invited_users, times : $scope.add_shift.time_list};

        FFApi.callAPI("addNewShift", query).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            if(_.has(response, 'shift')) {
                $scope.add_shift.time_list = [];
                $scope.previousState.url = 'schedule.unassigned-shifts';
                $scope.previousState.params = {};
                $state.go('schedule.shift', {shiftId: response.shift});
            }
        });

    };



    $scope.hours = [];
    $scope.minutes = [];
    for(var h = 0; h <= 23; h++){
        $scope.hours.push(h < 10 ? '0' + h : h);
    }
    for(var m = 0; m < 59; m += 5){
        $scope.minutes.push(m < 10 ? '0' + m : m);
    }
    $scope.getUsers = function(){
        return _shared_users;
    };


});
/**
 * Created by IMRANEXPT on 10/17/15.
 */
FoodFriendsApp.controller("shiftDetailsController", function($scope, $state, $stateParams, FFApi, Shift){
    //$scope.setDate(moment().format("YYYY-MM-DD"))s;
    $scope.setShift({});
    getShiftDetails();

    function getShiftDetails(){
        var shift_ID = _.has($stateParams, 'shiftId') ? _.parseInt($stateParams.shiftId) : 0;
        FFApi.callAPI("getShiftDetails", {id : shift_ID}).success(function(response){
            if(!FFApi.handleResponse(response)) {
                $state.go('schedule.shifts');
            }else{
                $scope.setShift(new Shift(response.shift));
                $scope.shift.newMessage = '';
                $scope.shift.date = moment($scope.shift.date);
                if($scope.previousState.url == 'schedule.shift.users') {
                    $scope.previousState.url = 'schedule.shifts';
                    $scope.previousState.params = {};
                }
            }
        });
    }
    $scope.SwapShift = function(){
        FFApi.callAPI("approveSwapRequest", {shift_ID : $scope.shift.id}).success(function(r){
            if(!FFApi.handleResponse(r)) return false;
            $state.go('schedule.shift', {shiftId : r.assigned_ID});
        });
    };
    $scope.sendShiftMessage = function(){
        FFApi.callAPI("addShiftComment", {shift_ID : $scope.shift.shift_ID, comment : $scope.shift.newMessage}).success(function(response){
           if(!FFApi.handleResponse(response)) return false;
            $scope.shift.newMessage = '';
            getShiftDetails();
        });
    };
    $scope.checkInOut = function(action, time_row){
        $scope.shift.checkinout(action, time_row).then(function(){
            getShiftDetails();
        });

    };
    $scope.CancelShift = function(reason){
        $scope.shift.cancel(reason).then(function(response){
            getShiftDetails();

        });
    };
    $scope.confirmShift = function(){
        $scope.shift.confirm().then(function(){
            getShiftDetails();
        })  ;
    };


});
/**
 * Created by IMRANEXPT on 10/18/15.
 */
FoodFriendsApp.controller("swapRequestsController", function($scope, $state, FFApi){
    $scope.setLastPage();
    $scope.setDate(moment().format("YYYY-MM-DD"));
    $scope.shifts = {};
    $scope.found_shifts = false;
    var week_day = moment($scope.date);
    $scope.weekdays = [];
    for(var i=0; i<7; i++){
        var _dd = moment(week_day).add(i,'d');
        $scope.shifts[_dd.format('YYYY-MM-DD')] = new Array();
        $scope.weekdays.push(_dd);
    }


    initSwapList();
    $scope.hasShifts = false;

    $scope.approve = function(shift, e){
        e.stopPropagation();
        FFApi.callAPI("approveSwapRequest", {shift_ID : shift.shift_ID}).success(function(r){
            if(!FFApi.handleResponse(r)) return false;
            $scope.inAction = false;
            initSwapList();
        })
    };
    function initSwapList(){
        //$scope.shifts = {};
        FFApi.callAPI("getSwapRequests", {}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            if(_.size(response.shifts) > 0)
                _.forEach(response.shifts, function(shifts, date) {
                    if (!_.has($scope.shifts, date)) {
                        $scope.shifts[date] = [];
                        $scope.weekdays.push(moment(date));
                    }
                    if(_.size(shifts)  > 0) $scope.found_shifts = true;
                    $scope.shifts[date] = _.map(shifts, function(shift){ shift.inAction = false; return shift; });
                });
        });
    }
});
/**
 * Created by IMRANEXPT on 10/18/15.
 */
FoodFriendsApp.controller("unassignedShiftsController", function($scope, $state, FFApi){
    $scope.setLastPage();
    $scope.setDate(moment().format("YYYY-MM-DD"));
    $scope.shifts = {};
    var shifts = {};
    $scope.hasShifts = false;
    var week_day = moment($scope.date);
    $scope.weekdays = [];
    for(var i=0; i<7; i++){
        var _dd = moment(week_day).add(i,'d');
        console.log(_dd.format('YYYY-MM-DD'));
        $scope.shifts[_dd.format('YYYY-MM-DD')] = new Array();
        $scope.weekdays.push(_dd);
    }
    FFApi.callAPI("getUnassignedScheduleShifts" , {}).success(function(response){
        if(!FFApi.handleResponse(response)) return false;
        shifts = response.shifts;
        _.forEach(response.shifts, function(shifts, date){
           if(!_.has($scope.shifts, date)) $scope.shifts[date] = new Array();
            if(_.size(shifts) > 0 && _.has($scope.shifts, date))
            for(var x in shifts){
                $scope.shifts[date].push(shifts[x]);
            }

        });
        //console.log($scope.shifts);
    });
});
/**
 * Created by imranexpt on 23/12/2016.
 */
FoodFriendsApp.controller("workExperienceCtrl", function($scope, $state, FFApi, $timeout, jobPost, $document, WorkExperience) {
    $scope.dropdowns = {position : false, restaurant : false, date : false};
    $scope.work = new WorkExperience();// {restaurant : {name : '', id : 0}, title : '', date : ''};
    $scope.work.title = '';
    $scope.work.restaurant = '';
    $scope.work.from= '';
    $scope.work.title_other = '';
    $scope.work.to = '';
    $scope.work.restId  = '';
    $scope.work._addRest = false;
    $scope.search = {query : '', restaurants : [], interval : null};
    $scope.lang = FFApi.lang;
    $scope.work_list = FFApi.work_list;
    $scope.foundResults = null;
    $scope.getWorkOption = function(opt){ return FFApi.getWorkOption(opt); };
    $scope.openDropdown = function(key, e){
        if(!_.isUndefined(e)) e.stopPropagation();
        for(var x in $scope.dropdowns){
            if(key == x){
                $scope.dropdowns[key] = !$scope.dropdowns[key];
            }else{
                $scope.dropdowns[x] = false;
            }
        }
        //$scope.dropdowns[key] = !$scope.dropdowns[key];
        if(key == 'restaurant'){
            var box = document.querySelector('.work-field.restaurant .dropdown-content');
            var span = document.querySelector('.work-field.restaurant');
            var spanL = span.getBoundingClientRect();
            if(!$state.includes("profile")) {
                box.style.left = '-' + spanL.left + 'px';
            }else {

            }
            //var layout = document.querySelector('div.view-animation');
            //box.style.height = layout ? (layout.getBoundingClientRect().height-box.getBoundingClientRect().top) + 'px' : '';
            //console.log(layout.getBoundingClientRect().height);
        }
    };
    $scope.getFormattedDate = function(date,lang){
        lang = FFApi.getLang('label_' + lang);
        //console.log(date);
        if(!date) return lang;
        return moment(date).format('MMM YYYY');
    }
    //document.on("click")
    $document.on("click", function(){
        $scope.dropdowns = {position : false, restaurant : false, date : false};
        $scope.$apply();
    });
    $scope.selectRestaurant = function(restaurant){
        //e.stopPropagation();
        $scope.work._addRest = false;
        if(_.isUndefined(restaurant)){
            $scope.work.restId = 0;
            $scope.work.restaurant = $scope.search.query;
            $scope.work._addRest = true;
        }else {
            $scope.work.restId = restaurant.company_id;
            $scope.work.restaurant = restaurant.restaurant_name; //= {id : restaurant.company_id, name : restaurant.restaurant_name};
        }
        $scope.openDropdown('restaurant');
    };
    $scope.save = function(){
        $scope.work.save().then(function(){
            $scope.$emit("WRK_SAVED");
            if($state.is('profile.restaurants') || $state.is("profile.info")){
                $scope.$parent.initProfile();
            }else {
                $state.reload();
            }
        });
    };

    function search(){
        $scope.foundResults = null;
        $scope.search.restaurants = [];
        $scope.work.restId = 0;
        FFApi.callAPI("searchRestaurants", {query : $scope.search.query, type : 'restaurant', 'limit' : 10, 'forwk' : true}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.search.restaurants = response.restaurants;
            $scope.foundResults = _.size(response.restaurants) > 0;
            //console.log(response.restaurants);
        });
    }
    $scope.$watch("search.query", function(query){
        if(query.length >= 2){
            $timeout.cancel($scope.search.interval);
            $scope.search.interval = $timeout(search, 500);
        }
        if(query === ''){
            $timeout.cancel($scope.search.interval);
            $scope.search.restaurants = [];
            $scope.search.query = '';
        }
    });
});
/**
 * Created by IMRANEXPT on 10/17/15.
 */
FoodFriendsApp.controller("messagesBaseController", function ($scope, FFApi, $state) {
    $scope.setLastPage();
    $scope.messageSingle = {id: 0, reply: '', message: null};
    $scope.newMessage = {text: '', users: [], group: '', groupImage: '', ia: false, special: false};
    //$scope.ia = false;
    $scope.title = FFApi.getLang('messages_heading');
    var message_info = false;
    $scope.messageRecepients = [];
    $scope.setVar = function (key, val) {
        $scope[key] = val;
    }
    $scope.isMessagesInfo = function () {
        return message_info;
    };
    $scope.toggleMessageInfo = function (e) {
        e.stopPropagation();
        message_info = !message_info;

    };
    $scope.hideMessageInfo = function () {
        message_info = false;
    };
    $scope.setTitle = function (title) {
        $scope.title = title;
    };

    $scope.replyToMessage = function () {
        FFApi.callAPI("postReply", {
            message_id: $scope.messageSingle.id,
            text: $scope.messageSingle.text
        }).success(function (r) {
            $scope.messageSingle.text = "";
            if (!FFApi.handleResponse(r)) return false;
            $state.reload();
        });
    };
    $scope.sendNewMessage = function () {
        FFApi.callAPI("composeNewMessage", $scope.newMessage).success(function (r) {
            if (!FFApi.handleResponse(r)) return false;
            $scope.newMessage = {text: '', users: [], group: '', groupImage: ''};
            $state.go('messages.message', {messageId: r.messageId});
        });
    }
});
FoodFriendsApp.controller("messagesController", function ($scope, FFApi, $state, $stateParams) {
    $scope.messages = [];
    $scope.hasGroups = function () {
        return _.keys($scope.messages).length > 1;
    }
    $scope.setTitle(FFApi.getLang('messages_heading'));
    FFApi.callAPI("messages", {}).success(function (r) {
        $scope.newMessage.ia = false;
        if (!FFApi.handleResponse(r)) return false;
        $scope.newMessage.ia = r.ia;
        $scope.messages = _.groupBy(

                _.map(r.messages,function (message) {
                if (_.isArray(message.recipients))
                    message.recipients = message.recipients.join(', ');
                return message;
            })
            , function (msg) {
            return msg.type == 'specialGroup' ? 'Special Group' : 'Messages';
        });
    });
})
    .controller("messageController", function ($state, $stateParams, $scope, FFApi) {
        var message_id = _.has($stateParams, 'messageId') ? _.parseInt($stateParams.messageId) : 0;
        $scope.newMessage = {text: '', user: 0};

        $scope.messages = [];
        FFApi.callAPI("message", {message_id: message_id}).success(function (r) {
            if (!FFApi.handleResponse(r)) return false;
            $scope.messages = r.messages;
            $scope.setVar('messageRecepients', r.users);
            $scope.messageSingle.message = r.messages;
            $scope.messageSingle.id = message_id;
            var title = _.slice(_.pluck(r.messages, 'display_name'), 0, 3).join(",");
            if (_.size(r.group) > 0) title = r.group;
            $scope.setTitle(title);
            sortMessages();
        });

        function sortMessages() {
            $scope.messages = _.sortByOrder($scope.messages, function (message) {
                var d = moment(message.date).unix();
                return d;
            }, 'desc');
        }

        $scope.getMessageInterval = function (date, short) {
            var obj = moment(date);
            var minutes = Math.floor((+new Date() - (+obj.valueOf())) / 60000);
            if (minutes < 60)
                return minutes + ' min';
            else if (minutes > 60 && minutes < 1440)
                return Math.floor(minutes / 60) + ' hours';
            else if (minutes > 1440 && minutes < 2880)
                return 'igår';
            else
                return typeof(short) !== 'undefined' ? obj.format('D MMMM') : obj.format("MMMM D, YYYY h:mma");
        };

    })

    .controller("newMessageController", function ($scope, $state, FFApi, $timeout) {
        $scope.search = '';
        //console.log($scope.newMessage);
        window.addEventListener('native.keyboardshow', keyboardShowHandler);
        window.addEventListener('native.keyboardhide', keyboardHideHandler);


        function keyboardShowHandler(e){
            var footer = document.querySelector('.message-reply-footer');
            //mainElm.style.marginTop = (e.keyboardHeight-45) + 'px';
            footer.style.marginTop = '-' + (e.keyboardHeight) + 'px';
            //console.log('Keyboard height is: ' + e.keyboardHeight);
            document.body.scrollTop = 0;
        }
        function keyboardHideHandler(e){
            //var mainElm = document.querySelector('.messages-compose');
            var footer = document.querySelector('.message-reply-footer');
            //mainElm.style.marginTop = '';
            footer.style.marginTop = '';
        }
        $scope.setTitle(FFApi.getLang('message_compose_heading'));
        $scope.newMessageUserGroup = 'all';
        $scope.restaurant_id = 0;
        $scope.newMessage.groupImage = '';
        $scope.newMessage.group = '';
        var users = [];
        var restaurants = [];
        var selected = [];
        var selected_restaurants = [];
        var _pre_selected = FFApi.getSharedData();
        if (_pre_selected && _.has(_pre_selected, 'custid') && _pre_selected.custid > 0) {
            selected.push(_pre_selected);
            $scope.newMessage.users.push(_pre_selected.custid);
        }

        var search_timer = null;
        $scope.switchTab = function (tab) {

            $scope.search = '';
            $scope.restaurant_id = 0;
            $scope.newMessageUserGroup = tab;
            if (tab == 'restaurants') {
                searchRestaurants();
            } else {
                selected_restaurants = [];
                searchUsers();
            }
        };

        $scope.getUsers = function () {
            return users;
        };
        $scope.getRestaurants = function () {
            return restaurants;
        };

        $scope.getSelectedUsers = function () {
            //return _.find()
            return selected;
        };
        $scope.selectedRestaurant = function (restid) {
            return _.includes(selected_restaurants, restid);
        };
        $scope.selectRestaurant = function (restid) {
            //$scope.newMessageUserGroup = 'all';
            //$scope.restaurant_id = restid;
            FFApi.callAPI('searchMembers', {query: '', restid: restid}).success(function (r) {
                if (!FFApi.handleResponse(r)) return false;
                $scope.restaurant_id = 0;
                var indx = _.includes(selected_restaurants, restid);
                var rm = false;
                if (indx) {
                    _.pull(selected_restaurants, restid);
                    rm = true;
                } else {
                    selected_restaurants.push(restid);
                }
                if (_.size(r.results) > 0) {
                    _.forEach(r.results, function (u) {
                        $scope.selectUser(u, rm);
                    });
                }
                //$timeout.cancel(search_timer);
            });
            //searchUsers();
        };

        $scope.selectUser = function (user, removeD) {
            //user.selected = !user.selected;
            user.selected = false;
            var indx = _.findIndex(selected, {custid: user.custid});
            if (indx >= 0) {
                selected.splice(indx, 1);
                user.selected = false;
            } else {
                if (_.isUndefined(removeD) || removeD == false) {
                    user.selected = true;
                    selected.push(user);
                }
            }

            $scope.newMessage.users = _.pluck(selected, 'custid');
        };
        $scope.$watch("search", function (val) {
            if (!_.isNull(val) && _.size(val) > 2) {
                if (search_timer !== null) $timeout.cancel(search_timer);
                search_timer = $timeout(applySearch, 500);
            }
        });
        function searchRestaurants() {
            restaurants = [];
            FFApi.callAPI('searchMemberWithRestaurants', {query: $scope.search}).success(function (r) {
                if (!FFApi.handleResponse(r)) return false;
                restaurants = r.results;
                if (search_timer !== null) $timeout.cancel(search_timer);
                //$timeout.cancel(search_timer);
            });
        }

        function searchUsers() {
            users = [];
            //console.log($scope.restaurant_id);
            FFApi.callAPI('searchMembers', {query: $scope.search, restid: $scope.restaurant_id}).success(function (r) {
                if (!FFApi.handleResponse(r)) return false;
                $scope.restaurant_id = 0;
                users = r.results;
                if (search_timer !== null) $timeout.cancel(search_timer);
                //$timeout.cancel(search_timer);
            });
        }

        searchUsers();

        function applySearch() {
            switch ($scope.newMessageUserGroup) {
                case 'all':
                    searchUsers();
                    break;
                case 'restaurants':
                    searchRestaurants();
                    break;
            }
        }

        $scope.selectGroupImage = function () {
            if (typeof(navigator.camera) == 'undefined') return;
            $timeout(function () {
                navigator.camera.getPicture(function (imageData) {
                        $scope.newMessage.groupImage = 'data:image/jpeg;base64,' + imageData;
                        $scope.$apply();
                    }, function (message) {

                    },
                    {
                        quality: 80,
                        destinationType: Camera.DestinationType.DATA_URL,
                        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
                        encodingType: Camera.EncodingType.JPEG,
                        allowEdit: true,
                        targetWidth: 300,
                        targetHeight: 300
                    }
                );
            }, 0);
        }

    })
    .controller("messageInfoController", function ($scope, $state, $stateParams, FFApi, $timeout) {
        $scope.message_info = {gid: 0, group: '', users: [], image: '', dataImage: '', owned: false};

        FFApi.callAPI("message", {message_id: $stateParams.messageId}).success(function (r) {
            if (!FFApi.handleResponse(r)) return false;
            if (_.size(r.group) > 0) $scope.message_info.group = r.group;
            $scope.message_info.image = r.group_image;
            $scope.message_info.gid = r.gpid;
            $scope.message_info.owned = r.owned;
            if (_.size(r.users) > 0) {
                _.forEach(r.users, function (u) {
                    u.selected = true;
                    $scope.message_info.users.push(u);

                });
            }
        });
        $scope.updateMessage = function () {
            if ($scope.message_info.owned == false) return false;
            FFApi.callAPI("updateMessageInfo", {
                group_id: $scope.message_info.gid,
                group: $scope.message_info.group,
                image: $scope.message_info.image
            }).success(function (r) {
                if (!FFApi.handleResponse(r)) return false;
                $state.go('messages.message', {messageId: $stateParams.messageId});
            });
        };
        $scope.selectGroupImage = function () {
            if ($scope.message_info.owned == false) return false;
            if (typeof(navigator.camera) == 'undefined') return;
            $timeout(function () {
                navigator.camera.getPicture(function (imageData) {
                        $scope.message_info.image = 'data:image/jpeg;base64,' + imageData;
                        $scope.message_info.dataImage = 'data:image/jpeg;base64,' + imageData;
                        $scope.$apply();
                    }, function (message) {

                    },
                    {
                        quality: 80,
                        destinationType: Camera.DestinationType.DATA_URL,
                        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
                        encodingType: Camera.EncodingType.JPEG,
                        allowEdit: true,
                        targetWidth: 300,
                        targetHeight: 300
                    }
                );
            }, 0);
        }
    });

/**
 * Created by imranexpt on 23/11/2016.
 */
/**
 * Created by imranexpt on 25/10/2016.
 */
FoodFriendsApp.controller("wallController", function($scope, $state, FFApi, $timeout) {
    var offset = 1;
    var scrollInProgress = false;
    var uploadedPostImage = document.getElementById('upload-pic-thumb');
    $scope._post = {text : '', picture : '', alert : false};
    $scope.publishPost = publishPost;
    $scope.wall = [];
    $scope.resetPostPicture = function(){
        $scope._post.picture = '';
        uploadedPostImage.src = '';
    };
    var scrollEnded = false;
    $scope.hasPostPicture = function(){
        return _.size($scope._post.picture) >= 10;
    }
    function publishPost(){
        FFApi.callAPI("publishWallPost", {post : $scope._post}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.wall.unshift(response.post);
            $scope._post = {text : '', picture : '' , alert : true};
            var firstPost = document.querySelector('.wall-post');
            var stop = firstPost.getBoundingClientRect().top;
            var appContent = document.querySelector('.app-content');
            uploadedPostImage.src = '';
            if(appContent)
                appContent.scrollTop = stop-100;
            $timeout(function(){ $scope._post.alert = false}, 2000);
            //document.querySelector(".")
        });
    }

    $scope.uploadPostPicture = function(){
        if(typeof(navigator.camera) == 'undefined') return;
        $timeout(function(){
            navigator.camera.getPicture(function(imageData){
                    imageData = 'data:image/jpeg;base64,' + imageData;
                    $scope._post.picture = imageData;
                    uploadedPostImage.src = imageData;
                    $scope.$apply();
                }, function(message){

                },
                {
                    quality: 80,
                    destinationType: Camera.DestinationType.DATA_URL,
                    sourceType : Camera.PictureSourceType.PHOTOLIBRARY,
                    encodingType: Camera.EncodingType.JPEG,
                    allowEdit : true,
                    targetWidth: 300,
                    targetHeight: 300
                }
            );
        },0);
    };

    function initWall(append){
        FFApi.callAPI('getWallPosts', {offset : offset}).success(function (response) {
            if(_.isUndefined(append))
                $scope.wall = response.wall;
            else {
                if(offset >= 2 && _.size(response.wall) == 0) {
                    scrollEnded = true;
                    console.log("Scroll ended");
                }
                for(var x in response.wall){
                    $scope.wall.push(response.wall[x]);
                }
                scrollInProgress = false;
            }
        });

    }
    initWall();

    $scope.$on("SCROLL_EVENT", function(){
        if(scrollEnded == true) return false;
        if(scrollInProgress == true) return;
        scrollInProgress = true;
        offset++;
        initWall(true);
    });
});

/**
 * Created by IMRANEXPT on 10/17/15.
 */
FoodFriendsApp.controller("profileBaseController", function($scope, $state, $stateParams, FFApi, $timeout, $sce) {
    $scope.profile_type = 'self';
    $scope.profile_info = {following : true};
    $scope.schedule = {};
    //$scope._work = new WorkExperience();
    $scope.profile_info_save = {};
    $scope.mentions = false;
    var _userId = 0;
    $scope.wallPosts = [];
    var offset = 1;
    //console.log($scope.profile_info);
    $scope.deleteWork = function(work,event){
        event.preventDefault();
        event.stopPropagation();
        if(typeof(cordova) == 'undefined') {
            if (!confirm(FFApi.getLang('delete_job_confirm'))) return false;
            removeWorkExperience(work);
        }else {
            FFApi.confirm(FFApi.getLang('delete_job_confirm'), function (btnPressed) {
                if(btnPressed == 1)
                    removeWorkExperience(work);

            });
        }

        //

    };
    function removeWorkExperience(work){
        FFApi.callAPI("removeWorkExperience", {work: work}).success(function (response) {
            if (!FFApi.handleResponse(response)) return false;
            $scope.initProfile();
        });
    }
    $scope.getMapUrl = function(){
        var city_name = $scope.profile_info.city_name + ',' + $scope.profile_info.country;
        return $sce.trustAsResourceUrl('https://maps.googleapis.com/maps/api/staticmap?center=' + city_name + '&zoom=13&size=500x200&maptype=roadmap&markers=color:red%7C%7C' + city_name);

    };
    $scope.getRegisterAge = function(){
        var registered = moment($scope.profile_info.user_registered);
        //console.log(registered.format('YYYY-MM-DD'));
        if(!registered.isValid()) return '0 days';
        var _days = FFApi.lang == 'sw' ? ' dagar' : ' days';
        var _months = FFApi.lang == 'sw' ? ' månader' : ' months';
        var _years = FFApi.lang == 'sw' ? ' år' : ' years';
        var _weeks = FFApi.lang == 'sw' ? ' veckor' : ' weeks';
        var diff_days = moment().diff(registered,'d');
        var diff_months = moment().diff(registered,'months');
        var diff_years = moment().diff(registered,'years');
        if(diff_years >= 1)
            return diff_years + _years;
        if(diff_months >= 1) return diff_months + _months;
        if(diff_days >= 7) return Math.ceil(diff_days/7) + _weeks;
        if(diff_days >= 1) return diff_days + _days;
        return diff_days + _days;
    };
    $scope.saveOtherWork = function(){
        if(!$scope.profile_type == 'self') return false;
        FFApi.callAPI("saveOtherWork", {work : $scope.profile_info.other_work_experience}).success(function(r){
            if(!FFApi.handleResponse(r)) return false;
            $state.go('profile.info', {reload : true});
            $scope.$broadcast("close_layer");
            //FFApi.alert("Work information is saved");
        }) ;
    };
    $scope.getProfileName = function(){
        var display_name = $scope.profile_info.display_name;
        var nickname    =   $scope.profile_info.nickname;
        if(nickname && nickname.length > 1){
            return $scope.profile_info.first_name + " '" + nickname + "' " + $scope.profile_info.last_name;
        }
        return display_name;
    };
    var scrollInProgress = false;
    $scope._userId = 10;
    $scope.checkScroll = function(){
        $scope.$broadcast("SCROLL_EVENT");
        if(!_.has($stateParams,'userId')) return;
        if(scrollInProgress == true) return;
        scrollInProgress = true;
        FFApi.callAPI('getProfileWallPosts', {offset : offset, userId : _userId}).success(function (response) {
            if(!FFApi.handleResponse(response)) return false;
            offset++;
            scrollInProgress = false;
            for(var x in response.wall){
                $scope.wallPosts.push(response.wall[x]);
            }
        });
    };
    $scope.takeProfilePicture = function(){
        if(!$state.is('profile.edit')){
            $scope.profile_info.image_popup = !$scope.profile_info.image_popup;
            return false;
        }
        if(typeof(navigator.camera) == 'undefined') return;
        $timeout(function(){
            navigator.camera.getPicture(function(imageData){
                    imageData = 'data:image/jpeg;base64,' + imageData;
                    FFApi.callAPI('uploadPhoto', {"image-data" : imageData}).success(function (r) {
                        if (!FFApi.handleResponse(r)) return false;
                        $state.reload();
                    });
                }, function(message){

                },
                {
                    quality: 80,
                    destinationType: Camera.DestinationType.DATA_URL,
                    sourceType : Camera.PictureSourceType.PHOTOLIBRARY,
                    encodingType: Camera.EncodingType.JPEG,
                    allowEdit : true,
                    targetWidth: 300,
                    targetHeight: 300
                }
            );
        },0);
    };
    $scope.getWorkOption = function(opt){ return FFApi.getWorkOption(opt);  };
    $scope.getWorkDuration = function(from, to){
        from = !_.isEmpty(from) ? moment(new Date(from)).valueOf() : 0;
        to = !_.isEmpty(to) ? moment(new Date(to)).valueOf() : 0;
        if(from > 0) {
            to = to > 0 ? to : moment().valueOf();
            if(to < from) return '';
            return humanizeDuration((to - from), {language : FFApi.lang, units : ['y','mo'], round : true});
        }
        return '';
    };
    $scope._checkWorkDate = function(dd){
        //console.log(dd);
        if(!_.isEmpty(dd)){
            var d_check = moment(new Date(dd));
            if(d_check.isValid())
                return d_check.format('MMM YYYY');
        }
        return dd;
    };
    
    $scope.schedule_date = moment().format("YYYY-MM-DD");
    $scope.initProfile = function(type, userId){
        $scope.mentions = false;

        $scope.profile_type = _.isUndefined(type) && _.isUndefined(userId) ? 'self' : type;
        $scope.profile_info = {following : true};
        $scope.schedule = {};
        $scope.profile_info_save = {};
        $scope.wallPosts = [];
        $scope.coworkers = [];
        $scope.restaurants = [];
        var completed = false;
        var profileLoaded = false;
        var user_query = {};
        if(!_.isUndefined(userId)) {
            _userId = userId;
            user_query['userId'] = _.parseInt(userId);
        }else{
            _userId = 0;
            user_query['report_date'] = $scope.schedule_date;
        }
        //console.log("Loading profile", $state.current.name);
        FFApi.callAPI('getMyProfileVars', user_query).success(function (r) {
            profileLoaded = true;
            if (!FFApi.handleResponse(r)) return false;
            $scope.mentions = r.mentions;
            $scope.setProfileVars(r);
            //if($state.is('profile.info') && _.isEmpty($scope.coworkers) && (_.isEmpty($scope.profile_info.address) && _.isEmpty($scope.profile_info.mobile) && _.isEmpty($scope.profile_info.url))){
            //    $state.go('profile.edit');
            //}
        });
    }
    $scope.profileConnectWithMember = function(){
        FFApi.callAPI("connectWithMember", {userId : _userId}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            FFApi.alert("You are now connected with " + $scope.profile_info.display_name);
            $scope.profile_info.following = true;
        });
    };
    $scope.setProfileVars = function(r){
        $scope.profile_info = r.info;
        $scope.profile_info.image_popup = false;
        completed = r.info.completed;
        if(!moment().isValid(r.info.dateofbirth))
            r.info.dateofbirth = '';
        $scope.profile_info_save = r.info;
        $scope.schedule = {daily : r.schedule_report.shifts, totals :  r.schedule_report.totals};
        $scope.restaurants = _.map(r.restaurants, function(r){
            if(r.thumb == null)
                r.thumb = 'default_restaurant.jpg';
            return r;
        });
        $scope.profile_info.lang = angular.copy(FFApi.getLangCode());
        $scope.coworkers = r.my_coworkers;
        $scope.wallPosts = r.wallPosts;
        $scope._userId = _userId;

    };
    $scope.profileSendMessage = function(){
        var user = {};
        user.first_name = $scope.profile_info.first_name;
        user.last_name = $scope.profile_info.last_name;
        user.display_name = $scope.profile_info.display_name;
        user.member_picture = $scope.profile_info.profile_image;
        user.user_email = $scope.profile_info.user_email;
        user.selected = 0;
        user.work_title = $scope.profile_info.work_title;
        user.work_company = $scope.profile_info.work_company;
        user.custid = $scope.profile_info.custid;
        FFApi.setSharedData(user);
        $state.go('messages.compose', {});
    };
    $scope.getOtherWorkWithBR = function(){
        console.log($scope.profile_info.other_work_experience.replace(/(?:\r\n|\r|\n)/g,"<br/>"));
        return $scope.profile_info.other_work_experience.replace(/(?:\r\n|\r|\n)/g,"<br/>");
    }
});
FoodFriendsApp.controller("profileRestaurantsController", function($scope, $state, $stateParams, FFApi, $timeout) {
    if(_.isEmpty($scope.profile_info))
        $scope.initProfile();







});
FoodFriendsApp.controller("profileScheduleController", function($scope, $state, $stateParams, FFApi, $timeout) {
    $scope.active_schedule = FFApi.getActiveSchedule();
    $scope.initSchReport = function(step, user_id){
        if(step > 0)
            $scope.schedule_date = moment($scope.schedule_date).add(Math.abs(step), 'month').format('YYYY-MM-DD');
        else
            $scope.schedule_date = moment($scope.schedule_date).subtract(Math.abs(step), 'month').format('YYYY-MM-DD');
        FFApi.callAPI('getMyProfileVars', {userId : user_id, report_date : $scope.schedule_date}).success(function (r) {
            if (!FFApi.handleResponse(r)) return false;
            $scope.setProfileVars(r);
        });
    };
});
FoodFriendsApp.controller("profileController", function($scope, $state, $stateParams, FFApi, $timeout, $document){
    //if(_.has($stateParams, 'userId') && _.parseInt($stateParams.userId) > 0) $scope.profile_type = 'public';
    //if()
    //console.log($state.current);
    if($state.is('profile.info'))
        $scope.initProfile();

    var popups = {experience : false, other_work : false};
    $scope.$on("close_layer", function(){
        popups.experience = false;
        popups.other_work = false;
    });
    if(_.isEmpty($scope.profile_info) || $scope.profile_info.self == false)
        $scope.initProfile();
    $scope.$on("WRK_SAVED", function(){
        popups = {experience : false, other_work : false};
    });
    $scope.childState = function(what){
        if(_.isArray(what)) {
            for (var x in what)
                if ($state.is('profile.' + what[x])) return true;
            return false;
        }
        //if(what == 'shift' && $state.includes('schedule.shift')) return true;
        return $state.is('profile.' + what);
    };
    $scope.showOtherWorkExperience = function(){
        return popups.other_work == true;
    };
    $scope.showWorkExperience = function(){
        return popups.experience == true;
    };
    $scope.toggleExperience = function(type, e){
        if(type == 'experience' || type == 'other_work') {
            popups[type] = !popups[type];
            if (type == 'other_work') {
                var elm = document.querySelector('.profile-other-work-row');
            }else{
                var elm = document.querySelector('.work-experience-popup-box');
            }
            var ap_c = document.querySelector('.app-content');
            if (elm)
                elm.style.top = (ap_c.scrollTop + (type == 'experience' ? 340 : 450)) + 'px';
        }else{
            popups.experience = false;
            popups.other_work = false;
        }
    };
    /*$scope.addWorkExperience = function(){
        $scope._work.save().then(function(response){
            if(!FFApi.handleResponse(response.data)) return false;
            $state.initProfile();
        });
    };*/


});

FoodFriendsApp.controller("publicProfileController", function($scope, $state, $stateParams, FFApi){
    $scope.$on("$viewContentLoaded", function(){
        $scope.initProfile('public', _.parseInt($stateParams.userId));
    });
    //$scope.getWorkOption = function(opt){ return FFApi.getWorkOption(opt);  };
    $scope.$on("$destroy", function(){
        console.log("Now Leaving", $state.includes('profile.public').name);
        $scope.profile_info = {following : true};
    });

})
    .controller("profileVarsController", function($scope){

}).controller('profileEditController', function($scope, $state, $stateParams, FFApi, $timeout){
    $scope.initProfile();
    $scope.$on("CALL_SAVE", $scope.saveProfile);


    function onSuccess(imageData) {
        var image = document.getElementById('myImage');
        image.src = "data:image/jpeg;base64," + imageData;
    }

    function onFail(message) {
        alert('Failed because: ' + message);
    }
    $scope.saveProfile = function(){
        FFApi.callAPI('saveMyProfile', {profile : $scope.profile_info_save}).success(function (r) {
            if (!FFApi.handleResponse(r)) return false;
            $scope.profile_info = angular.copy($scope.profile_info_save);
            $scope.setInfo({display_name : $scope.profile_info.display_name, work_title : $scope.profile_info.work_title, work_company : $scope.profile_info.work_company});
            $state.go('profile.info');
            if($scope.profile_info.lang !== FFApi.getLangCode()){
                FFApi.setLang($scope.profile_info.lang);
                window.location = 'index.html';
            }
        });
    };
})
    .controller('profileEditPasswordController', function($scope, $state, $stateParams, FFApi, $timeout) {
        //$scope.initProfile();
        $scope.password = {old : '', new : '', new_2 : ''};
        $scope.resetPassword = function(){
            FFApi.callAPI('updateUserPassword', {password_list : $scope.password}).success(function (r) {
                if (!FFApi.handleResponse(r)) return false;
                FFApi.alert(FFApi.getLang('password_success'),'FoodFriends');
                //FFApi.setData("password", r.auth_hash);
                $state.go('profile.info');
            });
        };
    });
/**
 * Created by IMRANEXPT on 10/16/15.
 */
FoodFriendsApp.controller("loginController", function($state, $scope, FFApi, $openFB, $stateParams){
    $scope.loginUser = {username : FFApi.getData("username",""), password : FFApi.getData("password",""), fb_token : '', li_token : '', firstname : '', lastname : ''};
    $scope.error = false;
    $scope.login = function(){
        $scope.error = false;
        FFApi.setData("schedules", "");
        FFApi.setData("my_work","");
        FFApi.callAPI("doAuth", $scope.loginUser).success(function(response){
            if(response.success !== true && !response.profile){
                $scope.error = response.errors;
                return false;
            }
            FFApi.setData("username", $scope.loginUser.username);
            FFApi.setData("password", response.auth_hash);
            if(_.has(response, 'schedules')) {
                FFApi.setData("schedules",JSON.stringify(response.schedules));
                if(_.size(response.schedules) > 0){
                    FFApi.setData("schedule_ID", _.first(response.schedules)['ID']);
                    var info = {display_name : response.display_name, work_title : response.work_title, work_company : response.work_company, member_picture : response.profile_image};
                    var companies = _.size(response.member_companies) > 0 ? response.member_companies : [];
                    $scope.setInfo(info);
                    FFApi.setData("myInfo", JSON.stringify(info));
                    FFApi.setData("my_work", JSON.stringify(companies));
                    $state.go('foodfriends.home');
                    return;
                }
            }
            //FFApi.setData("schedules", $scope.loginUser.username);
            $state.go("foodfriends.home");
        });
    };
    function loginWithFB(){
        $openFB.api({path : '/me', params : {fields : ['id','first_name','last_name','email']}}).then(function(res){
            $scope.loginUser.username = res.email;
            $scope.loginUser.firstname = res.first_name;
            $scope.loginUser.lastname = res.lastname;
            $scope.login();
        }, function(err){
            console.log("Error while getting profile");
        });
    }
    /*$scope.$on("ngOpenFB:loadend", function(e, url){
        SocialGap.checkOutput(function(e){
            console.log("Success", e);
        }, function(e){
            console.log("Failed", e);
        });
    });*/

    $scope.loginWithLinkedIn = function() {
        SocialGap.Linkedin_PerformLogon(function(eToken){
            FFApi.getLinkedInProfile(eToken).success(function(info){
                $scope.loginUser.username = info.emailAddress;
                $scope.loginUser.li_token = eToken;
                $scope.loginUser.firstname = info.firstName;
                $scope.loginUser.lastname = info.lastName;
                $scope.login();
            });
        }, function(e){
            $scope.error = 'Could not authenticate you with linkedin api';
        });
    };
    $scope.loginWithFacebook = function() {
        $openFB.isLoggedIn()
            .then(function (loginStatus) {
                $scope.loginUser.fb_token = loginStatus.authResponse.token;
                loginWithFB();
            }, function (err) {
                $openFB.login({scope: 'email'})
                    .then(function (token) {
                        $scope.loginUser.fb_token = token;
                        loginWithFB();
                    }, function (err) {
                        FFApi.alert('Could not login to your facebook account');
                    });
            });
    }

    if(!_.isUndefined($stateParams.fbconnect) && $stateParams.fbconnect == 'yes'){
        $scope.loginWithLinkedIn();
    }

});
FoodFriendsApp.controller("logoutController", function($state, FFApi){
    FFApi.logout();
});
FoodFriendsApp.controller('registerController', function($state, $scope, FFApi, $timeout, $openFB, $stateParams){
    $scope.registerUser = {firstname : '', lastname : '', email : '', password : '',  profile_picture : '', fb_profile : false, dateofbirth : ''};
    //$scope.errors = {fn  :false, ln : false}
    $scope.hasImage = function(){ return $scope.registerUser.fb_profile ==true ? true : $scope.registerUser.profile_picture.indexOf('data:image') >= 0; };
    $scope.registerFF = function(){
        $scope.submitted = true;
        //console.log(if(!$scope.registerForm.$valid))
        if(!$scope.registerForm.$valid) return false;
        saveUser();
    };
    function saveUser(){
        FFApi.callAPI("registerUser", $scope.registerUser).success(function(response){
            if(_.has(response,'registerError') && response.registerError == true){
                $state.go('login', {'fbconnect' : 'yes'});
            }else {
                if(!FFApi.handleResponse(response)) return false;
                FFApi.setData("username", $scope.registerUser.email);
                FFApi.setData("password", response.auth_hash);
                //FFApi.alert("Registration successful. Please confirm your email to get started");
                $state.go('register-complete', {
                    fromregister: 'yes',
                    'fbConnect': $scope.registerUser.fb_profile == true ? 'yes' : 'no'
                });
            }
            
        }); 
    }
    $scope.selectUserImage = function() {
        if (typeof(navigator.camera) == 'undefined') return;
        $timeout(function () {
            navigator.camera.getPicture(function (imageData) {
                    $scope.registerUser.profile_picture = 'data:image/jpeg;base64,' + imageData;
                    $scope.$apply();
                }, function (message) {

                },
                {
                    quality: 80,
                    destinationType: Camera.DestinationType.DATA_URL,
                    sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
                    encodingType: Camera.EncodingType.JPEG,
                    allowEdit: true,
                    targetWidth: 300,
                    targetHeight: 300
                }
            );
        }, 0);
    }
    function _updateFromLI(){
        SocialGap.Linkedin_PerformLogon(function(eToken){
            FFApi.getLinkedInProfile(eToken).success(function(info){
                $scope.registerUser.firstname = info.firstName;
                $scope.registerUser.lastname = info.lastName;
                $scope.registerUser.email = info.emailAddress;
                //$scope.registerUser.dateofbirth = res.birthday;
                $scope.registerUser.fb_profile = true;
                toDataUrl(info.pictureUrl, function(url){
                    $scope.registerUser.profile_picture = url;
                    saveUser();
                });
            });
        }, function(e){
            $scope.error = 'Could not authenticate you with linkedin api';
        });
    }
    function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
    }
    function _updateFromFB(){
        $openFB.api({path : '/me', params : {fields : ['id','first_name','last_name','email','birthday', 'picture.type(large)']}}).then(function(res){
            $scope.registerUser.firstname = res.first_name;
            $scope.registerUser.lastname = res.last_name;
            $scope.registerUser.email = res.email;
            //$scope.registerUser.dateofbirth = res.birthday;
            $scope.registerUser.profile_picture = res.picture.data.url;
            $scope.registerUser.fb_profile = true;
            saveUser();
            //$scope.$apply();
            //$scope.saveProfile();
        }, function(err){
            //$openFB.logout();

        });
    }
    $scope.signupWithLI = function(){
        _updateFromLI();
    }
    $scope.signupWithFB = function(){
        $openFB.isLoggedIn()
            .then(function( loginStatus ) {
                console.log("User is already logged in, fetch profile now", loginStatus);
                _updateFromFB();
            } , function( err ) {
                //not logged in
                console.log("User is not logged in, lets get a login first", arguments);
                $openFB.login({scope: 'email'})
                    .then(function( token) {
                        console.log("Login successful, lets get profile data", token);
                        _updateFromFB();
                    }, function( err ) {
                        // error logging in
                        console.log("FB Login errors", err);
                        //$openFB.logout();
                    });
            });
    };
    if(!_.isUndefined($stateParams.fbconnect) && $stateParams.fbconnect == 'yes'){
        _updateFromLI();
    }


})
.controller('registerCompleteController', function($scope, $state, FFApi, $stateParams, $timeout){
    $scope.user = {work_experience : '', work_title : '',restaurant : '', work : [], dateofbirth : '', password_reset : '', fb_profile : ($stateParams.fbConnect === 'yes' ? true : false)};
    $scope.fav_restaurant = {query : '', results : [], timer : null};
    var do_not_search = false;

    $scope.$watch("fav_restaurant.query", function(q){
        if($scope.fav_restaurant.timer !== null) $timeout.cancel($scope.fav_restaurant.timer);
        if(q.length > 3 && do_not_search == false){
            $scope.fav_restaurant.timer = $timeout(searchFavRestaurant, 500);
        }
        do_not_search = false;
    });
    function searchFavRestaurant(){
        FFApi.callAPI("searchRestaurants", {query : $scope.fav_restaurant.query, type : 'restaurant'}).success(function(response){
           if(!FFApi.handleResponse(response)) return false;
           $scope.fav_restaurant.results = response.restaurants;
           //console.log(re)
        });
    }
    $scope.selectRestaurant = function(restaurant){
        $scope.user.restaurant = restaurant.company_id;
        $scope.fav_restaurant.query = restaurant.restaurant_name;
        $scope.fav_restaurant.results = [];
        do_not_search = true;
    }


    $scope.addWorkExperience = function(){ $scope.user.work.push({title : '', from : '', to : ''})};
    $scope.submitted = false;
    $scope.confirm_message = $stateParams.fromregister == 'yes';
    $scope.continueRegister = function(){
            $scope.submitted = true;
            if(!$scope.registerForm.$valid) return false;
            FFApi.callAPI("registerComplete", $scope.user).success(function(response) {
                if(response.success !== true) {
                    FFApi.alert(response.errors);
                    return false;
                }
                //if (!FFApi.handleResponse(response)) return false;
                //FFApi.alert("Registration successful. Please confirm your email to get started");
                $state.go('foodfriends.home');
            });
    }
    $scope.$on("$viewContentLoaded", function(){
        if(!FFApi.hasAuth() && $scope.confirm_message == false){
            $state.go('login');
        }
    })
});
FoodFriendsApp.controller("resetPasswordCtrl", function($scope, $state, FFApi, $timeout){
    $scope.resetUser = {email : '', password : '', password2 : '', code : '', error : '', win : 'email', success : false};
    $scope.sendResetPassEmail = function(){
        FFApi.callAPI("resetPasswordEmail", {email : $scope.resetUser.email}).success(function(response){
           if(!FFApi.handleResponse(response)) return false;
           $scope.resetUser.win = 'code';

        });
    };
    $scope.resetPassword = function(){
        FFApi.callAPI("resetPassword", {email : $scope.resetUser.email, code : $scope.resetUser.code, password : $scope.resetUser.password, password2 : $scope.resetUser.password2}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.resetUser.success = true;
            $timeout(function(){
                $state.go('login');
            },4000);
        });
    };
    $scope.verifyResetCode = function(){
        FFApi.callAPI("verifyResetCode", {email : $scope.resetUser.email, code : $scope.resetUser.code}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.resetUser.win = 'password';
        });
    };
});
/**
 * Created by IMRANEXPT on 10/16/15.
 */
FoodFriendsApp.controller("splashController", function($scope, $state, FFApi){
    $scope.view = 'home';

    $scope.loginUser = {username : (FFApi.getData("username") || ""), password : (FFApi.getData("password") || "")};
    if($scope.loginUser.username.length > 0 && $scope.loginUser.password.length > 0){
        $state.go('schedule.shifts');
        return;
    }
    $state.toggleLoginForm = function(){
        $scope.view = $scope.view == 'home' ? 'login' : 'home';
    };
    $scope.toggleHome = function(){
        $scope.view = 'home';
    };


});
/**
 * Created by IMRANEXPT on 12/11/15.
 */
FoodFriendsApp.controller("industryGuideController", function($scope, $state, FFApi, $stateParams, $sce, $q, $timeout){
    $scope.categories = [];
    $scope.title = FFApi.getLang('guide_title');
    $scope.restaurant = {};
    $scope.setRestaurant = function(r){
        $scope.restaurant = r;
    };

    FFApi.callAPI("getGuideCategories", {}).success(function(response){
        if(!FFApi.handleResponse(response)){
            return false;
        }else{
            $scope.categories = response.categories;
            $scope.setTitle(FFApi.getLang('guide_title'));
        }
    });
    $scope.setTitle = function(t){
        $scope.title = t;
    };
    
});

FoodFriendsApp.controller('industryGuideCategoryController', function($scope, $state, FFApi, $stateParams){
    var catId  = _.parseInt($stateParams.catId);
    //console.log($scope.categories);
    var cat = _.find($scope.categories, {id : catId});
    $scope.setTitle(cat ? cat.cat_name : FFApi.getLang('guide_title'));
    $scope.companies = [];
    FFApi.callAPI("getGuideCompanies", {catId : catId}).success(function(response){
        if(!FFApi.handleResponse(response)){
            return false;
        }else{
            $scope.companies = response.companies;
        }
    });
});
FoodFriendsApp.controller('industryGuideCompanyController', function($scope, $state, FFApi, $stateParams, $sce){
    var companyId  = $stateParams.companyId;
    $scope.company = {};
    //$scope.restaurant = {};

    if(companyId > 0)
    FFApi.callAPI("getGuideCompany", {companyId : companyId}).success(function(response){
        if(!FFApi.handleResponse(response)){
            return false;
        }else{
            $scope.company = response.company;
            $scope.setRestaurant({company_name : response.company.name, company_pictures : response.company.image, city_name : response.company.city_name, company_phone : response.company.phone, company_address : response.company.address});
            $scope.setTitle( response.company.name);
        }
    });
    $scope.getMapLocation = function(){
      var url = 'http://maps.google.com/maps/api/staticmap?center=' + $scope.company.address + '&size=100x100&zoom=15&maptype=roadmap&format=jpg&markers=%7C' + $scope.company.address + '&sensor=false';
      return $sce.trustAsResourceUrl(url);
    };
    $scope.getProductImage = function(image){

    }
});
/**
 * Created by imranexpt on 28/10/2016.
 */
FoodFriendsApp.controller('restaurantController', function($scope, $state, $stateParams, FFApi, WorkExperience, $sce){
    $scope.restaurant = {};
    $scope.mentions = false;
    $scope.work_form = false;
    $scope._work = new WorkExperience();
    FFApi.callAPI('getRestaurant', {restaurantId : $stateParams.companyId}).success(function (r) {
        if (!FFApi.handleResponse(r)) return false;
        $scope.restaurant = r.restaurant;
        $scope.mentions = r.mentions;
        $scope._work.restId = $scope.restaurant.ID;
        $scope._work.restaurant = $scope.restaurant.company_name;
    });
    $scope.toggleWorkForm = function(){
        $scope.work_form = !$scope.work_form;
    };
    $scope.getFirstMension = function(){
        return [_.first($scope.mentions)];
    };
    $scope.addWorkExperience = function(){
        $scope._work.save().then(function(response){
            if(!FFApi.handleResponse(response.data)) return false;
            $state.reload();
        });
    };
    $scope.hasBooking = function(){
        return $scope.restaurant.hash_tag.length >= 10;
    };
    $scope.loadWidget = function(){
        $state.go('restaurant.widget', {location : false});
    };
    $scope.gotoUserProfile = function(userid){
        if(userid && userid > 0)
            $state.go('profile.public', {userId : userid});
    };
    $scope.getBookingUrl = function(){
        var discounted = '';
        if($scope.restaurant.discount_mealid > 0)
            discounted = '&mealid=' + $scope.restaurant.discount_mealid + '&special_meals=7';
      return $sce.trustAsResourceUrl("http://www.waiteraid.com/clients/main/bokning.php?from_url=foodfriends_app&css=22&hash=" + $scope.restaurant.hash_tag + discounted);
    };
    $scope.toggleRestaurantLike = function(){
        FFApi.callAPI("toggleRestaurantLike", {restaurantId : $scope.restaurant.ID}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.restaurant.like = !$scope.restaurant.like;
        });
    };
    $scope.toggleRestaurantVisit = function(){
        FFApi.callAPI("toggleRestaurantVisit", {restaurantId : $scope.restaurant.ID}).success(function(response){
            if(!FFApi.handleResponse(response)) return false;
            $scope.restaurant.visit = !$scope.restaurant.like;
        });
    };
    $scope.lang = FFApi.lang;
    $scope.work_list = FFApi.work_list; /*[
        {en : 'Owner', sw : 'Krögare'},
        {en : 'Restaurant manager', sw : 'Restaurangchef'},
        {en : 'Maitre d', sw : 'Hovmästare'},
        {en : 'Chef', sw : 'Kock'},
        {en : 'Kallskanka', sw : 'Kallskänka'},
        {en : 'Sous chef', sw : 'Souchef'},
        {en : 'Kitchen manager', sw : 'Köksmästare'},
        {en : 'Dish washer', sw : 'Diskare'},
        {en : 'Nisse', sw : 'Nisse'},
        {en : 'Waiter', sw : 'Servis'},
        {en : 'Bartender', sw : 'Bartender'},
        {en : 'Bar nisse', sw : 'Barnisse'},
        {en : 'Sommelier', sw : 'Sommelier'},
        {en : 'Mood manager', sw : 'Mood manager'}
    ];*/

});